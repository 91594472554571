import React from "react";
import { useFolderview } from "../../../Logic/UserFlow/useFolderview";
import Style from "../FolderView/FolderView.module.scss";

const DesktopEditButton = ({ setIsEditSelected }) => {
  const { handleFinalEdit } = useFolderview();

  return (
    <button
      className={`btn btn-border-grey ${Style.Select_photos_btn_mob} ms-2`}
      onClick={() => {
       handleFinalEdit();
      }}
    >
      Edit
    </button>
  );
};

export default DesktopEditButton;
