import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Assets from "../../Assets";
import Style from "./HeaderLogo.module.scss";

const HeaderLogo = ({ userProfileData }) => {
  const location = useLocation();
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  let { user_token } = useParams();

  useEffect(() => {
    document.body.getAttribute("data-theme") === "black"
      ? setIsDarkTheme(true)
      : setIsDarkTheme(false);
  }, [document.body.getAttribute("data-theme")]);

  return (
    <div className={`me-auto ${Style.main_logos_wrap} ${
      location.pathname === `/${user_token}` ||
            location.pathname === `/${user_token}/folder-view` ||
            location.pathname === `/${user_token}/folder-view/selected` ? Style.userflow_pages : ''
    }`}>
      <div className={Style.main_logos_item}>
        <Link
          to={
            location.pathname === `/${user_token}` ||
            location.pathname === `/${user_token}/folder-view` ||
            location.pathname === `/${user_token}/folder-view/selected`
              ? `/${user_token}`
              : "/"
          }
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <figure className={Style.MainHeader__logo}>
            <img
              src={
                !isDarkTheme
                  ? Assets.photofind_logo_light
                  : Assets.photofind_logo_dark
              }
              alt="photofind-logo"
            />
          </figure>
        </Link>
      </div>
      {location.pathname === "/userflow" ||
      location.pathname === "/userflow-folder-view" ? (
        <>
          <div className={Style.main_logos_item}>
            <figure className={Style.MainHeader__logo}>
              <img src={Assets.SarithaLogoDark} alt="photofind-logo" />
            </figure>
          </div>
        </>
      ) : (
        <>
          {userProfileData?.logo_url &&
          !(
            location.pathname === "/" ||
            location.pathname === "/plans" ||
            location.pathname === "/plans/" ||
            location.pathname === "/contact" ||
            location.pathname === "/contact/" ||
            location.pathname === "/guidelines-and-terms" ||
            location.pathname === "/guidelines-and-terms/" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/privacy-policy/" ||
            location.pathname === "/cancellation-and-refund-policy" ||
            location.pathname === "/cancellation-and-refund-policy/" ||
            location.pathname === "/faq" ||
            location.pathname === "/faq/"
          ) ? (
            <div className={Style.main_logos_item}>
              <figure className={Style.MainHeader__logo}>
                <img src={userProfileData?.logo_url} alt="photofind-logo" />
              </figure>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderLogo;
