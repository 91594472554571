import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";
import { useDimensions } from "../../../Logic/Dimensions";
import { useHeader } from "../../../Logic/Header/useHeader";
import MainFooter from "../MainFooter";
import MainHeader from "../MainHeader";

const CommonLayout = ({ pageClass, children, style, noFooter }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const windowDimensions = useDimensions();
  const { userProfileData } = useHeader();
  const { mainPageStatus } = useSelector((state) => state.userFlow);
  let { user_token } = useParams();
  useEffect(() => {
    document.body.classList.toggle(
      "pt-mobile",
      location.pathname === `/${user_token}` && windowDimensions.width >= 576
    );
  }, [location.pathname]);

  return (
    <>
      {location.pathname === `/${user_token}` ||
      windowDimensions.width >= 576 ? (
        <MainHeader />
      ) : (
        <> </>
      )}
      <main
        className={`
          ${pageClass} ${noFooter ? "noFooter" : ""}
          ${
            mainPageStatus === "loading" &&
            (location.pathname === `/${user_token}` ||
              location.pathname === `/${user_token}/`)
              ? "shimmer-loader"
              : ""
          } `}
        style={style}
      >
        {children}
      </main>
      {/* {noFooter ? "" : <MainFooter />} */}
    </>
  );
};

export default CommonLayout;
