import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Style from "./CommonModal.module.scss";
import { useParams } from "react-router-dom";

const CommonModal = ({
  backdrop,
  keyboard,
  showModal,
  setShowModal,
  title,
  content,
  className,
  footer,
  centered,
  size,
  isheader,
  clearState,
  uploadSuccess,
}) => {
  const navigate = useNavigate();

  let { user_token } = useParams();
  const handleClose = () => {
    setShowModal(false);
    if (typeof clearState === "function") {
      clearState();
    }
    if (uploadSuccess == true) {
      navigate(`/${user_token}`);
      window.location.reload();
    }
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={keyboard}
      centered={centered}
      size={size}
      className={`${Style.CommonModal} ${className}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isheader ? "" : <h4> {title}</h4>}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
