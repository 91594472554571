import React, { useState } from "react";
import Style from "../FolderView/FolderView.module.scss";

function PhotosViewHeading({
  status,
  capitalizeFirstLetter,
  albumName,
  isPreselect,
  PhotosCount,
  windowDimensions,
}) {
  return (
    <h2
      className={`h5 ${status !== "succeed" && "text-loading d-inline-block"}`}
    >
      {status === "succeed" ? (
        <>
          {!isPreselect || windowDimensions.width < 768
            ? capitalizeFirstLetter(albumName)
            : `Selected photos (${PhotosCount})`}
        </>
      ) : (
        <>Loading...</>
      )}
    </h2>
  );
}

export default PhotosViewHeading;
