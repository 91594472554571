// Common
import main_logo_light from '../../assets/logo-light.svg'
import main_logo_dark from '../../assets/logo-dark.svg'
import photofind_logo_light from '../../assets/photofind-logo-light.svg'
import photofind_logo_dark from '../../assets/photofind-logo-dark.svg'

// Home
import about_fpa_image from '../../assets/home/about-fpa-image.png'
import feature_image from '../../assets/home/feature-image.png'
import ipad_frame from '../../assets/home/video-player/ipad-frame.svg'
import video_poster from '../../assets/home/video-player/video-poster.png'
import bannerVideoThumbs from '../../assets/home/banner-video-thumbs.png'

import vector_spring_white from '../../assets/home/spring-white.svg'
import vector_spring_blue from '../../assets/home/spring-blue.svg'
import vector_sc_green from '../../assets/home/sc-green.svg'
import vector_sc_blue from '../../assets/home/sc-blue.svg'

import client_1 from '../../assets/home/clients/client-1.svg'
import client_2 from '../../assets/home/clients/client-2.svg'
import client_3 from '../../assets/home/clients/client-3.svg'
import client_4 from '../../assets/home/clients/client-4.svg'
import client_5 from '../../assets/home/clients/client-5.svg'

import userflow_img from '../../assets/userflow/userflow-img.jpg'
import jaimee_soto_logo from '../../assets/userflow/jaimee-soto-logo.png'
import successfully_sent_gif from '../../assets/userflow/successfully-sent.gif'

import weddingImg01 from '../../assets/userflow/folder-view/folder-ph01.jpg'
import weddingImg02 from '../../assets/userflow/folder-view/folder-ph02.jpg'
import weddingImg03 from '../../assets/userflow/folder-view/folder-ph03.jpg'
import weddingImg04 from '../../assets/userflow/folder-view/folder-ph04.jpg'
import weddingImg05 from '../../assets/userflow/folder-view/folder-ph05.jpg'
import weddingImg06 from '../../assets/userflow/folder-view/folder-ph06.jpg'
import weddingImg07 from '../../assets/userflow/folder-view/folder-ph07.jpg'
import weddingImg08 from '../../assets/userflow/folder-view/folder-ph08.jpg'
import weddingImg09 from '../../assets/userflow/folder-view/folder-ph09.jpg'
import weddingImg10 from '../../assets/userflow/folder-view/folder-ph10.jpg'
import weddingImg11 from '../../assets/userflow/folder-view/folder-ph11.jpg'
import weddingImg12 from '../../assets/userflow/folder-view/folder-ph12.jpg'
import weddingImg13 from '../../assets/userflow/folder-view/folder-ph13.jpg'
import weddingImg14 from '../../assets/userflow/folder-view/folder-ph14.jpg'
import weddingImg15 from '../../assets/userflow/folder-view/folder-ph14.jpg'


import imagemask from '../../assets/userflow/userflow-thumb-mask.png'
// Get Started
import img_placeholder from '../../assets/getstarted/img-placeholder.svg'
import img_theme_default from '../../assets/getstarted/theme-default.jpg'
import img_theme_dark from '../../assets/getstarted/theme-dark.jpg'
import img_theme_purple_twilight from '../../assets/getstarted/theme-purple-twilight.jpg'
import img_theme_marine_green from '../../assets/getstarted/theme-marine-green.jpg'
import img_theme_mango from '../../assets/getstarted/theme-mango.jpg'
import img_theme_skyscape from '../../assets/getstarted/theme-skyscape.jpg'


// Contact
import contact_banner from '../../assets/Contact/contact-banner.png'
import contact_banner_mob from '../../assets/Contact/contact-banner-mob.png'

// Dashboard
import JaimeeLogo from '../../assets/dashboard/jaimee-soto-logo.svg'
import SarithaLogo from '../../assets/dashboard/saritha-studio-logo.png'
import SarithaLogoDark from '../../assets/dashboard/saritha-studio-logo-black.png'
import placeholder_image_stack from '../../assets/dashboard/placeholder-image-stack.svg'

import photo1 from '../../assets/dashboard/photos/image1.png'
import photo2 from '../../assets/dashboard/photos/image2.png'
import photo3 from '../../assets/dashboard/photos/image3.png'
import photo4 from '../../assets/dashboard/photos/image4.png'
import photo5 from '../../assets/dashboard/photos/image5.png'
import photo6 from '../../assets/dashboard/photos/image6.png'
import photo7 from '../../assets/dashboard/photos/image7.png'
import photo8 from '../../assets/dashboard/photos/image8.png'
import photo9 from '../../assets/dashboard/photos/image9.png'
import photo10 from '../../assets/dashboard/photos/image10.png'
import photo11 from '../../assets/dashboard/photos/image11.png'
import photo12 from '../../assets/dashboard/photos/image12.png'
import photo13 from '../../assets/dashboard/photos/image13.png'
import photo14 from '../../assets/dashboard/photos/image14.png'

import submit_animation from '../../assets/dashboard/submit-animation.gif'

import collapse_holder from '../../assets/dashboard/collapse-holder.svg';
import blank_data_placeholder from '../../assets/dashboard/blank-data-placeholder.svg';
import placeholder_single_image from '../../assets/dashboard/placeholder-single-image.svg';
import blank_folder_placeholder from '../../assets/dashboard/blank-folder-placeholder.svg';

import play_lg from '../../assets/videoPlayer/play_lg.svg';
import pause_lg from '../../assets/videoPlayer/pause_lg.svg';

const Assets = {
  main_logo_light,
  main_logo_dark,
  photofind_logo_light,
  photofind_logo_dark,

  about_fpa_image,
  feature_image,
  ipad_frame,
  video_poster,
  bannerVideoThumbs,

  vector_spring_white,
  vector_spring_blue,
  vector_sc_green,
  vector_sc_blue,

  client_1,
  client_2,
  client_3,
  client_4,
  client_5,
  
  img_placeholder,
  img_theme_default,
  img_theme_dark,
  img_theme_purple_twilight,
  img_theme_marine_green,
  img_theme_mango,
  img_theme_skyscape,

  userflow_img,
  jaimee_soto_logo,
  successfully_sent_gif,

  weddingImg01,
  weddingImg02,
  weddingImg03,
  weddingImg04,
  weddingImg05,
  weddingImg06,
  weddingImg07,
  weddingImg08,
  weddingImg09,
  weddingImg10,
  weddingImg11,
  weddingImg12,
  weddingImg13,
  weddingImg14,
  weddingImg15,
  imagemask,
  
  submit_animation,

  JaimeeLogo,
  SarithaLogo,
  SarithaLogoDark,

  contact_banner,
  contact_banner_mob,
  placeholder_image_stack,

  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  
  collapse_holder,
  blank_data_placeholder,
  placeholder_single_image,

  play_lg,
  pause_lg,
  blank_folder_placeholder,
};

export default Assets;
