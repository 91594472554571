import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  getCurrentProjectDetails: { currentPageDetails: {} },
  createNewFolder: { staffList: [], eventCategories: [], events: [] },

  allFolders: {
    filter: { preSelect: false, selectedItems: [], order: "desc" },
    deleteSingleFolder: { selection: false, id: "" },
    deleteMultipleFolders: { selection: false },
    eventInfo: {
      status: false,
      data: "",
      shareInfo: { status: false, data: "" },
    },
    status: "idle",
    list: [],
    editFolder: { status: false, details: { staffIDs: "" } },
  },
};

export const getCurrentPageDetails = createAsyncThunk(
  "projectSingle/getCurrentPageDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `project/edit-project?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewFolderByProjectID = createAsyncThunk(
  "projectSingle/createNewFolderByProjectID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `project/edit-project?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStaffDetails = createAsyncThunk(
  "projectSingle/getStaffDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`staff/list-staff`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventCategory = createAsyncThunk(
  "projectSingle/getEventCategory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`album/event-category`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitCreateNewFolder = createAsyncThunk(
  "projectSingle/submitCreateNewFolder",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album/save-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFoldersByProjectID = createAsyncThunk(
  "projectSingle/getFoldersByProjectID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/album/list-album?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customLoadMore = createAsyncThunk(
  "projectSingle/customLoadMore",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/album/list-album?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFolderDetailsByID = createAsyncThunk(
  "projectSingle/getFolderDetailsByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/album/get-album?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventNames = createAsyncThunk(
  "projectSingle/getEventNames",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/album/album-names`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFolders = createAsyncThunk(
  "projectSingle/deleteFolders",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/album/delete-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSingleFolder = createAsyncThunk(
  "projectSingle/deleteSingleFolder",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/album/delete-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventInfoPopupDetails = createAsyncThunk(
  "projectSingle/getEventInfoPopupDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/album/get-album?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventShareInfoPopupDetails = createAsyncThunk(
  "projectSingle/getEventShareInfoPopupDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/album/share?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const projectSingle = createSlice({
  name: "projectSingle",
  initialState,
  reducers: {
    storeCurrentPageDetails: (state, action) => {
      state.currentPageDetails = action.payload;
      state.getCurrentProjectDetails.currentPageDetails = action.payload;
    },
    toggleEditFolder: (state, action) => {
      const { status, id } = action.payload;
      state.allFolders.editFolder.status = status;
      if (status === false) {
        state.allFolders.editFolder.details = "";
      }

      if (id != "" && id != undefined) {
        state.allFolders.editFolder.id = id;
      } else {
        delete state.allFolders.editFolder.id;
      }
    },

    selectionCancel: (state, action) => {
      state.allFolders.filter.preSelect = false;
      state.allFolders.filter.selectedItems = [];
    },

    togglePreSelect: (state, action) => {
      state.allFolders.filter.preSelect = action.payload;
    },

    selectAllFolders: (state, action) => {
      state.allFolders.filter.selectedItems = action.payload;
    },

    unSelectAllFolders: (state, action) => {
      state.allFolders.filter.selectedItems = [...new Array(0).keys()];
    },

    singleSelectionFolder: (state, action) => {
      if (state.allFolders.filter.selectedItems === undefined) {
        state.allFolders.filter.selectedItems = [];
      }

      if (state.allFolders.filter.selectedItems.includes(action.payload)) {
        state.allFolders.filter.selectedItems =
          state.allFolders.filter.selectedItems.filter(function (item) {
            return item !== action.payload;
          });
      } else {
        state.allFolders.filter.selectedItems = [
          ...state.allFolders.filter.selectedItems,
          ...[action.payload],
        ];
      }
    },

    selectItemToBeDelete: (state, action) => {
      state.allFolders.deleteSingleFolder.selection = true;
      state.allFolders.deleteSingleFolder.id = action.payload;
    },

    cancelDeleteAction: (state, action) => {
      state.allFolders.deleteSingleFolder.selection = false;
      state.allFolders.deleteSingleFolder.id = "";
    },

    deleteMultipleStatus: (state, action) => {
      state.allFolders.deleteMultipleFolders.selection = action.payload;
    },

    changeFolderOrder: (state, action) => {
      state.allFolders.filter.order = action.payload;
      state.allFolders.list = {
        ...state.allFolders.list,
        current_page: 1,
      };
    },

    eventInfoPopup: (state, action) => {
      state.allFolders.eventInfo.status = action.payload;
    },

    shareInfoPopup: (state, action) => {
      state.allFolders.eventInfo.shareInfo.status = action.payload;
    },
  },
  extraReducers: {
    /****************
     * GET CURRENT PROJECT PAGE DETAILS*
     ****************/

    [getCurrentPageDetails.pending]: (state, action) => {
      state.getCurrentProjectDetails.status = "loading";
    },
    [getCurrentPageDetails.fulfilled]: (state, action) => {
      state.getCurrentProjectDetails.status = "succeed";
      state.getCurrentProjectDetails.currentPageDetails =
        action.payload.data.result;
    },
    [getCurrentPageDetails.rejected]: (state, action) => {
      state.getCurrentProjectDetails.status = "error";
    },

    /****************
     * GET ALL STAFFS*
     ****************/

    [getStaffDetails.pending]: (state, action) => {
      //state.createNewFolder.staffList.status = "loading";
    },
    [getStaffDetails.fulfilled]: (state, action) => {
      //state.createNewFolder.staffList.status = "succeed";
      const staffArray = [];
      action.payload.data.result.data?.forEach((data) => {
        staffArray.push({ value: data.id, label: data.name });
      });
      state.createNewFolder.staffList = staffArray;
    },
    [getStaffDetails.rejected]: (state, action) => {
      //  state.createNewFolder.staffList.status = "error";
    },

    /****************
     * GET ALL STAFFS*
     ****************/

    [getEventCategory.pending]: (state, action) => {},
    [getEventCategory.fulfilled]: (state, action) => {
      const Categories = [];
      action.payload.data.result?.forEach((data) => {
        Categories.push({ value: data.id, label: data.name });
      });
      state.createNewFolder.eventCategories = Categories;
    },
    [getEventCategory.rejected]: (state, action) => {},

    /****************
     * SUBMIT CREATE NEW FOLDER*
     ****************/

    [submitCreateNewFolder.pending]: (state, action) => {
      state.createNewFolder.status = "loading";
    },
    [submitCreateNewFolder.fulfilled]: (state, action) => {
      /**************************************************************************************
       * AFTER CREATING NEW FOLDER IS PUSHES INTO THE STACK OF FOLDER LISTED ON THE WEBSITE *
       **************************************************************************************/
      if (action.payload.data.success === true) {
        const obj = {
          id: action.payload.data.result.id,
          name: action.payload.data.result.name,
          location_name: action.payload.data.result.location_name,
          date: action.payload.data.result.date,
        };

        const events = current(state.createNewFolder.events);

        const { meta } = action;
        var oldPacket = current(state);
        var newPacket = [];

        oldPacket.allFolders.list.data.forEach((item) => {
          if (action.meta.arg.id === item.id) {
            let obj = {
              date: action.meta.arg?.date,
              id: action.meta.arg?.id,
              location_name: action.meta.arg?.location_name,
              name: action.meta.arg?.name,
            };

            if (action.meta.arg?.event_category_id != undefined) {
              events.forEach((cat) => {
                if (cat.value == action.meta.arg?.album_name_id) {
                  obj.name = cat.label;
                }
              });
            }

            newPacket.push(obj);
          } else {
            newPacket.push(item);
          }
        });

        if (action.payload.data.result.id != undefined) {
          state.allFolders.list.data = [
            ...[obj],
            ...state.allFolders.list.data,
          ];
        } else {
          state.allFolders.list.data = newPacket;
        }
      }

      state.createNewFolder.status = "succeed";
    },
    [submitCreateNewFolder.rejected]: (state, action) => {
      state.createNewFolder.status = "error";
    },

    /****************
     * GET ALL FOLDER BY PROJECT ID*
     ****************/

    [getFoldersByProjectID.pending]: (state, action) => {
      state.allFolders.status = "loading";
    },
    [getFoldersByProjectID.fulfilled]: (state, action) => {
      state.allFolders.status = "succeed";
      state.allFolders.list = action.payload.data.result;
    },
    [getFoldersByProjectID.rejected]: (state, action) => {
      state.allFolders.status = "error";
    },

    /****************
     * GET ALL FOLDER BY PROJECT ID*
     ****************/

    [getFolderDetailsByID.pending]: (state, action) => {
      state.allFolders.editFolder.loading = "loading";
    },
    [getFolderDetailsByID.fulfilled]: (state, action) => {
      state.allFolders.editFolder.loading = "succeed";
      state.allFolders.editFolder.details = action.payload.data.result;
      const staffIDs = [];

      action.payload.data.result?.staff.forEach((item) => {
        staffIDs.push(item.staff_id);
      });

      state.allFolders.editFolder.details.staffIDs = staffIDs;
    },
    [getFolderDetailsByID.rejected]: (state, action) => {
      state.allFolders.editFolder.loading = "error";
    },

    /****************
     * GET ALL FOLDER BY PROJECT ID*
     ****************/

    [getEventNames.pending]: (state, action) => {},
    [getEventNames.fulfilled]: (state, action) => {
      const Events = [{ value: "custom", label: "Custom" }];
      action.payload.data.result?.forEach((data) => {
        Events.push({ value: data.id, label: data.name });
      });

      state.createNewFolder.events = Events;
    },
    [getEventNames.rejected]: (state, action) => {},

    /*******************
     * LOAD MORE FOLDERS *
     ********************/

    [customLoadMore.pending]: (state, action) => {
      state.allFolders.status = "loading";
    },
    [customLoadMore.fulfilled]: (state, action) => {
      state.allFolders.status = "succeed";
      state.allFolders.list.data = [
        ...state.allFolders.list.data,
        ...action?.payload?.data?.result?.data,
      ];
      state.allFolders.list = {
        ...state.allFolders.list,
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [customLoadMore.rejected]: (state, action) => {
      state.allFolders.status = "error";
    },

    /*******************
     * DELETE FOLDERS *
     ********************/

    [deleteFolders.pending]: (state, action) => {
      state.allFolders.status = "loading";
    },
    [deleteFolders.fulfilled]: (state, action) => {
      state.allFolders.filter.status = "deleted";

      if (action.payload.data.success === true) {
        /************************************************************
         * REMOVEING DELETED ITEM FROM REDUX DATA HAS BEEN EXISTING *
         ************************************************************/
        state.allFolders.list.data = state.allFolders.list.data?.filter(
          function (item) {
            return !state.allFolders.filter.selectedItems.includes(item.id);
          }
        );

        /****************************************
         * RESETTING PRESELECT STATE SETS FALSE *
         ****************************************/

        state.allFolders.filter.preSelect = false;

        /****************************************
         * RESETTING SELECTED FOLDERS IDS TO EMPTY ARRAY [] *
         ****************************************/

        state.allFolders.filter.selectedItems = [];

        /******************************************
         * CLOSING THE DELETE CONFIRM MODAL POPUP *
         ******************************************/

        state.allFolders.deleteMultipleFolders.selection = false;
      }
    },
    [deleteFolders.rejected]: (state, action) => {
      state.allFolders.status = "error";
    },

    /*******************
     * SINGLE DELETE FOLDER *
     ********************/

    [deleteSingleFolder.pending]: (state, action) => {
      state.allFolders.deleteSingleFolder.status = "loading";
    },
    [deleteSingleFolder.fulfilled]: (state, action) => {
      state.allFolders.deleteSingleFolder.status = "deleted";

      if (action.payload.data.success === true) {
        /************************************************************
         * REMOVEING DELETED ITEM FROM REDUX DATA HAS BEEN EXISTING *
         ************************************************************/
        state.allFolders.list.data = state.allFolders?.list?.data?.filter(
          function (item) {
            return action?.meta?.arg?.id[0] != item?.id;
          }
        );
        state.allFolders.deleteSingleFolder.selection = false;
        state.allFolders.deleteSingleFolder.id = "";
      }
    },
    [deleteSingleFolder.rejected]: (state, action) => {
      state.allFolders.status = "error";
    },

    /*******************
     * GET EVENT INFO POPUP *
     ********************/

    [getEventInfoPopupDetails.pending]: (state, action) => {
      state.allFolders.eventInfo.loading = "loading";
    },
    [getEventInfoPopupDetails.fulfilled]: (state, action) => {
      state.allFolders.eventInfo.loading = "succeed";
      state.allFolders.eventInfo.data = action.payload.data.result;
      state.allFolders.eventInfo.shareInfo.id = action.meta.arg.id;
    },
    [getEventInfoPopupDetails.rejected]: (state, action) => {
      state.allFolders.eventInfo.loading = "error";
    },

    /*******************
     * GET EVENT INFO POPUP *
     ********************/

    [getEventShareInfoPopupDetails.pending]: (state, action) => {
      state.allFolders.eventInfo.shareInfo.loading = "loading";
    },
    [getEventShareInfoPopupDetails.fulfilled]: (state, action) => {
      state.allFolders.eventInfo.shareInfo.loading = "succeed";
      state.allFolders.eventInfo.shareInfo.data = action.payload.data.result;
    },
    [getEventShareInfoPopupDetails.rejected]: (state, action) => {
      state.allFolders.eventInfo.shareInfo.loading = "error";
    },
  },
});

export const {
  storeCurrentPageDetails,
  toggleEditFolder,
  togglePreSelect,
  selectAllFolders,
  unSelectAllFolders,
  singleSelectionFolder,
  selectionCancel,
  selectItemToBeDelete,
  cancelDeleteAction,
  deleteMultipleStatus,
  changeFolderOrder,
  eventInfoPopup,
  shareInfoPopup,
} = projectSingle.actions;

export default projectSingle.reducer;
