import React from 'react';
import CommonModal from '../../Modals/CommonModal';
import Style from "../FolderView/FolderView.module.scss";

function GoWithStudioSuggestionModal({ showModal, setShowModal,handleConfirm }) {
  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={showModal}
      setShowModal={setShowModal}
      centered={true}
      className={`type-sm confirm_modal ${Style.confirm_modal}`}
      title={<h4>Confirm to go with studio suggestions</h4>}
      content={
        <>
          <p>
            Confirm to go with studio suggestions, this can be edited from the
            final selection
          </p>
        </>
      }
      footer={
        <>
          <button className={`btn btn-primary`} onClick={()=>{
            handleConfirm();
            setShowModal(false);
          }}>Confirm</button>
          <button
            className="btn btn-border-grey"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </>
      }
    />
  );
}

export default GoWithStudioSuggestionModal;