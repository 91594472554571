import React from 'react'
import Style from './InfiniteDotLoader.module.scss'

const InfiniteDotLoader = ({type}) => {
    return (
        <>
        {type == "project" ? (
        <>
        <div className={Style.InfiniteDotLoader_wrap}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </>) : (
        <>
        <div className={Style.InfiniteDotLoader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </>
        )}
        </>
    )
}

export default InfiniteDotLoader