import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Keyboard,
  Controller,
  Pagination,
  Navigation,
  Lazy,
  EffectCoverflow,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Style from "./ViewAndAddPhoto.module.scss";
import { useState } from "react";
import { useRef } from "react";
import Icon from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";
import ViewAndAddPhotoSlide from "./ViewAndAddPhotoSlide";
import { useDispatch, useSelector } from "react-redux";
import {
  currentTabAction,
  updatePreselect,
} from "../../../store/slices/UserFlow/userFlowSlice";
import { useNavigate, useParams } from "react-router";

const ViewAndAddPhoto = ({
  slideData,
  setIsImagePreview,
  activeSlideIndex,
  type,
  getSelectedImagesCount,
  page,
  handleAddImagesToFinalSelection,
}) => {
  const windowDimensions = useDimensions();
  // const updateSwiper = useRef();
  const updateSwiperThumbs = useRef();
  const [isStartSlider, setIsStartSlider] = useState(null);
  const [isFirstSlider, setIsFirstSlider] = useState(null);
  const navigate = useNavigate();
  const [isNewAddedImage, setIsNewAddedImage] = useState(null);
  const { getSelectedImages } = useSelector((state) => state.userFlow);
  const dispatch = useDispatch();
  let { user_token } = useParams();

  const swiperNext = ()=>{
    updateSwiperThumbs.current.swiper.slideNext();
  }
  useEffect(() => {
    updateSwiperThumbs.current.swiper.slideTo(
      isFirstSlider === 0 ? 0 : activeSlideIndex === 0 ? 1 : activeSlideIndex,
      false,
      false
    );
  }, [updateSwiperThumbs, isFirstSlider]);

  return ReactDOM.createPortal(
    <>
      <div className={Style.image_preview}>
        <div className={Style.image_preview_header}>
          <div className={Style.image_preview_header_back_wrap}>
            <button
              className={`btn btn-border-white ${Style.image_preview_back_btn}`}
              onClick={() => setIsImagePreview(false)}
            >
              {windowDimensions.width >= 768 ? (
                <Icon color={"currentColor"} size={18} icon={"back-arrow"} />
              ) : (
                <Icon
                  color={"currentColor"}
                  size={12}
                  icon={"slider-nav-left"}
                />
              )}
            </button>
          </div>
          <div className={Style.image_preview_header_notification}>
            <p>
              {getSelectedImagesCount() > 0 ? (
                <>
                  {getSelectedImagesCount()} photo
                  {getSelectedImagesCount() === 1 ? "" : "s"} has been selected.
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <div
            className={Style.image_preview_header_done_wrap}
            onClick={() => {
              setIsImagePreview(false);
              dispatch(currentTabAction("Selected"));
              navigate(`/${user_token}/folder-view/selected`);
              handleAddImagesToFinalSelection();
            }}
          >
            {getSelectedImagesCount() > 0 ? (
              <>
                <button className={`btn btn-border-grey`}>
                  {" "}
                  {page != "finalSection" ? "Add to Final" : "X"}
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={Style.image_preview_body}>
          <Swiper
            modules={[
              Keyboard,
              Controller,
              Pagination,
              Navigation,
              Lazy,
              EffectCoverflow,

            ]}
            spaceBetween={6}
            slideToClickedSlide={true}
            slidesPerView={"auto"}
            ref={updateSwiperThumbs}
            centeredSlides={false}
            navigation={true}
            onSlideChange={(swiper) => setIsStartSlider(swiper.activeIndex)}
            onInit={() =>
              setIsFirstSlider(activeSlideIndex === 0 ? 0 : activeSlideIndex)
            }
            keyboard={{
              enabled: true,
            }}
          >
            {slideData?.map((data, index) => {
              return (
                <SwiperSlide
                  key={`thumb-${index}`}
                  data-slide={`${
                    isStartSlider - 8 <= index && isStartSlider + 8 >= index
                      ? isStartSlider - index
                      : " "
                  }`}
                >
                  <ViewAndAddPhotoSlide
                    isStartSlider={isStartSlider}
                    type={type}
                    data={data}
                    index={index}
                    suggested={data?.suggestedImages ? true : false}
                    isNewAddedImage={isNewAddedImage}
                    setIsNewAddedImage={setIsNewAddedImage}
                    page={page}
                    swiperNext={swiperNext}
                    handleAddImagesToFinalSelection={handleAddImagesToFinalSelection}
                  />
                </SwiperSlide>
              );
            })}
            {/* <div className={Style.swiper_btn_prev} ref={navigationPrevRef}>prev</div>
            <div className={Style.swiper_btn_next} ref={navigationNextRef}>next</div> */}
          </Swiper>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root")
  );
};

export default ViewAndAddPhoto;
