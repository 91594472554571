import React from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Style from "../FolderView/FolderView.module.scss";
import { UserFlowTabs } from "../UserFlowTabs";

const Tabs = ({
  count,
  sections,
  isSelected,
  setIsSelected,
  isShowTabsSmooth,
}) => {
  const { suggestedImages, allImages,finalSelection } = useSelector((state) => state.userFlow);

  return (
    <Col
      xl="12"
      className={`text-center text-sm-end my-auto 
     ${isShowTabsSmooth ? Style.FolderView_header_smooth_add : ""} 
    ${Style.FolderView_header_tabs_wrap}`}
    >
      <div className={Style.FolderView_header_btn_wrap}>
        <div
          className={`text-center text-sm-end my-auto order-md-2 order-1 ${Style.user_flow_folderview_header_btns} ${Style.FolderView_header_toggle_btn}`}
        >
          <UserFlowTabs
            allPhotos={`All Photos ${
              isNaN(suggestedImages?.total + allImages?.total) === false
                ? suggestedImages?.total + allImages?.total
                : 0
            }`}
            finalSelection={`Final selection ${
              finalSelection?.data?.length
                ? finalSelection?.data?.length
                : " (0)"
            }`}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
          />
        </div>
      </div>
    </Col>
  );
};

export default Tabs;
