import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Style from "./NotFoundPage.module.scss";

const NotFoundPage = () => {

  const { invalidURL } = useSelector((state) => state.userFlow);

  return (
    <CommonLayout>
      <div className={`${Style.NotFoundPage} inner-page `}>
        <Container>
          <div className="inner-page-wrap">
            <h1 className={Style.error_code}>404</h1>
            <h2 className={Style.error_name}> {invalidURL === true ? "invalid URL" : "Page Not Found"}</h2>
          </div>
        </Container>
      </div>
    </CommonLayout>
  )
}

export default NotFoundPage;