import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { currentTabAction } from "../../../store/slices/UserFlow/userFlowSlice";

import Style from "./UserFlowTabs.module.scss";
export const UserFlowTabs = ({
  allPhotos,
  finalSelection,
  activeTab,
  activeTabLeft,
  handleClickHandler,
  setIsSelected,
  isSelected,
}) => {
  const { currentTab } = useSelector((state) => state.userFlow);
  const [activeTabWidth, setActiveTabWidth] = useState();
  const [activeTabLeftItem, setActiveTabLeftItem] = useState();
  const location = useLocation();
  let { user_token } = useParams();
  const activeRef = useRef(null);
  useEffect(() => {
    setActiveTabWidth(activeRef?.current?.clientWidth);
  }, [activeTab, allPhotos, finalSelection]);

  const [toggleState, setToggleState] = useState("state1");

  const [buttonLeft, setButtonLeft] = useState(
    toggleState === "state1" ? 0 : 152
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ToggleSwitchRef = useRef();
  const [isActiveState, setIsActiveState] = useState(1);
  const handleToggle = (e, theme, type, path) => {
    setToggleState(theme);
    setButtonLeft(e.target.offsetLeft);
    // setIsSelected(!isSelected);
    dispatch(currentTabAction(type));
    navigate(path);
    // if (type == "all") {
    //   dispatch(updatePreselect(false));
    // }
  };

  useEffect(() => {
    setActiveTabWidth(activeRef.current.clientWidth);
    setActiveTabLeftItem(activeRef?.current?.offsetLeft);
  }, [currentTab, activeRef.current]);


  console.log(activeTabWidth,
    activeTabLeftItem, currentTab, "hey tab...");

  return (
    <>
      <div className={Style.ContentHeader__left}>
        <ul className={Style.ContentHeader__navList}>
          <li
            className={toggleState === "state1" ? "tabActive" : ""}
            ref={currentTab === "all" ? activeRef : null}
            onClick={(e) => {
              handleToggle(e, "state1", "all", "");
            }}
          >
            {allPhotos}
          </li>
          <li
            className={toggleState === "state2" ? "tabActive" : ""}
            ref={currentTab === "Selected" ? activeRef : null}
            onClick={(e) => {
              handleToggle(e, "state2", "Selected", "selected");
            }}
          >
            {finalSelection}
          </li>
          <li
            className={Style.activeTab__indicator}
            style={{
              transform: `translateX(${activeTabLeftItem}px)`,
              width: `${activeTabWidth}px`,
            }}
          ></li>
        </ul>
      </div>
    </>
  );
};
