import axios from "axios";

const BASE_URL = process.env.REACT_APP_APIURL;

let user = "";
let token = "";

export const getAxiosInstance = async () => {
  let lang =
    localStorage.getItem("lang") == undefined
      ? "en"
      : localStorage.getItem("lang");
  try {
    token = window.localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      params: { lang },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
