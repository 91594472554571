import React, { useState } from "react";
import { useFolderview } from "../../../Logic/UserFlow/useFolderview";
import Icon from "../../Layouts/Icons";
import Style from "../FolderView/FolderView.module.scss";

function FinalViewCancelButton({ setIsEditSelected, windowDimensions }) {
  //const { handleFinalSelectionPreSelection } = useFolderview();
  return (
    <button
      className={`btn btn-border-grey ${Style.Select_photos_btn_mob} ${
        windowDimensions.width >= 768 ? "ms-2" : "ms-auto"
      }`}
      onClick={() => {
        setIsEditSelected(false);
      //  handleFinalSelectionPreSelection(false);
      }}
    >
      Cancel
    </button>
  );
}

export default FinalViewCancelButton;
