import React from 'react';
import Style from "../FolderView/FolderView.module.scss";
import Assets from "../../Layouts/Assets";
import CommonModal from '../../Modals/CommonModal';

const ThankYouModal = ({ showModal, setShowModal, userToken,backHome }) => {
  
  const handleClose = (status) => {
    setShowModal(false);
    backHome();
  };

  return (
    <CommonModal
      backdrop={true}
      keyboard={false}
      showModal={showModal}
      setShowModal={handleClose}
      centered={true}
      className={`type-lg ${Style.thanks_modal}`}
      content={
        <>
          <div className="row">
            <div className="col-12 text-center">
              <div className={Style.successfully_sent_img}>
                <figure>
                  <img src={Assets.successfully_sent_gif} alt="wedding-img" />
                </figure>
              </div>
              <h3 className={`mb-3 ${Style.thaks_you_modal_title}`}>
                Thank you!
              </h3>
              <p className={`mb-4 ${Style.thaks_you_modal_content}`}>
                You have successfully sent all photos you want to showcase on
                your album.
              </p>
            </div>
          </div>
          <div className="w-100 text-center">
            <button
              onClick={handleClose}
              className="btn btn-primary px-md-4 footer-link"
            >
              <span className="px-sm-2">Close</span>
            </button>
          </div>
        </>
      }
      footer={null}
    />
  );
};

export default ThankYouModal;