import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useFolderview } from "../../../Logic/UserFlow/useFolderview";
import Style from "../FolderView/FolderView.module.scss";

const MobileSubmitButton = ({
  finalSelection,
  setIsConfirmSelectionModal,
}) => {
  return (
    <div className={Style.user_flow_folderview_footer}>
      <div className={Style.user_flow_folderview_footer_wrap}>
        <Container>
          <Row>
            <Col
              md="12"
              className={`text-center text-md-end my-auto ${Style.user_flow_folderview_footer_btns}`}
            >
              <button
                className={`btn btn-primary ${Style.user_flow_submit_seletion}`}
                disabled={finalSelection?.data?.length > 0 ? false : true}
                onClick={() => {
                  setIsConfirmSelectionModal(true);
                }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MobileSubmitButton;
