import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  studioName: {},
  studioEmail: {},
  studioMobile: {},
  currentScreen: "",
  isUpdatingEmail: false,
  isUpdatingPhone: false,
  openAuthModal: false,
  emailOtpVerify: "",
  mobileOtpVerify: "",
  isVerify: "idle",
  deleteUserAccount: {},
  socialMediaData: [],
  studioAddress: {},
};

export const postStudioName = createAsyncThunk(
  "preference/postStudioName",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-studio-name", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postStudioEmail = createAsyncThunk(
  "preference/postStudioEmail",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-email", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postStudioMobile = createAsyncThunk(
  "preference/postStudioMobile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-mobile", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyMobileOtp = createAsyncThunk(
  "preference/verifyMobileOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/otp-verify", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postResendStudioPhone = createAsyncThunk(
  "preference/postResendStudioPhone",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-mobile", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyEmailOtp = createAsyncThunk(
  "preference/verifyEmailOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/email-otp-verify", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postResendStudioEmail = createAsyncThunk(
  "preference/postResendStudioEmail",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-email", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "preference/deleteAccount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("preference/delete-account");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSocialMedia = createAsyncThunk(
  "preference/getSocialMedia",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("preference/social-media-types");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const postSocialMedia = createAsyncThunk(
  "preference/postSocialMedia",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        "preference/update-socialmedialink",
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postAddress = createAsyncThunk(
  "preference/postAddress",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("preference/change-address", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const studioDetailstSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    changeModal: (state, action) => {
      state.currentScreen = action.payload;
    },

    authModalHandler: (state, action) => {
      state.openAuthModal = action.payload;
    },

    isUpdatingEmailStatus: (state, action) => {
      state.isUpdatingEmail = action.payload;
    },

    isUpdatingPhoneStatus: (state, action) => {
      state.isUpdatingPhone = action.payload;
    },

 
  },
  extraReducers: {
    [postStudioName.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postStudioName.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.studioName = action.payload.data;
    },
    [postStudioName.rejected]: (state, action) => {
      state.status = "error";
    },

    [postStudioEmail.pending]: (state, action) => {
      state.status = "loading";
      state.isUpdatingEmail = false;
      state.message = "";
    },
    [postStudioEmail.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.isUpdatingEmail = true;
      state.studioEmail = action.payload.data;
    },
    [postStudioEmail.rejected]: (state, action) => {
      state.status = "error";
      state.isUpdatingEmail = false;
    },

    [postResendStudioEmail.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postResendStudioEmail.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.studioEmail = action.payload.data;
    },
    [postResendStudioEmail.rejected]: (state, action) => {
      state.status = "error";
    },

    [postStudioMobile.pending]: (state, action) => {
      state.status = "loading";
      state.isUpdatingPhone = false;
      state.message = "";
    },
    [postStudioMobile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.isUpdatingPhone = true;
      state.studioMobile = action.payload.data;
    },
    [postStudioMobile.rejected]: (state, action) => {
      state.status = "error";
      state.isUpdatingPhone = false;
    },

    [postResendStudioPhone.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postResendStudioPhone.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.studioMobile = action.payload.data;
    },
    [postResendStudioPhone.rejected]: (state, action) => {
      state.status = "error";
    },

    [verifyEmailOtp.pending]: (state, action) => {
      state.isVerify = "loading";
      state.message = "";
    },
    [verifyEmailOtp.fulfilled]: (state, action) => {
      state.isVerify = "succeed";
      state.emailOtpVerify = action.payload.data;
    },
    [verifyEmailOtp.rejected]: (state, action) => {
      state.isVerify = "error";
    },

    [verifyMobileOtp.pending]: (state, action) => {
      state.isVerify = "loading";
      state.message = "";
    },
    [verifyMobileOtp.fulfilled]: (state, action) => {
      state.isVerify = "succeed";
      state.mobileOtpVerify = action.payload.data;
    },
    [verifyMobileOtp.rejected]: (state, action) => {
      state.isVerify = "error";
    },

    [deleteAccount.pending]: (state, action) => {
      state.isVerify = "loading";
      state.message = "";
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.isVerify = "succeed";
      state.deleteUserAccount = action.payload.data;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.isVerify = "error";
    },

    [getSocialMedia.pending]: (state, action) => {
      state.isVerify = "loading";
      state.message = "";
    },
    [getSocialMedia.fulfilled]: (state, action) => {
      state.isVerify = "succeed";
      state.socialMediaData = action.payload.data.result;
    },
    [getSocialMedia.rejected]: (state, action) => {
      state.isVerify = "error";
    },

    [postSocialMedia.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postSocialMedia.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [postSocialMedia.rejected]: (state, action) => {
      state.status = "error";
    },

    [postAddress.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postAddress.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [postAddress.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const {
  changeModal,
  authModalHandler,
  isUpdatingEmailStatus,
  isUpdatingPhoneStatus,
} = studioDetailstSlice.actions;

export default studioDetailstSlice.reducer;
