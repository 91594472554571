import React, { forwardRef, useEffecf, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import PhotoFolderCard from "../../../../Cards/PhotoFolderCard";
import AllPhotosShimmer from "../../Common/AllPhotosShimmer";
import Style from "../../FolderView.module.scss";

const OtherPhotos = forwardRef(({ allImages, status, setIsImagePreview, setIsSlideKey, setImagetype, suggestedImages }, ref) => {
  return (
    <div className={Style.user_flow_folderview_otherPhotos}>
      <Row>
        <Col md={12} className="px-1 px-sm-2">
          <h4 className="h4 px-sm-1">
            {suggestedImages?.data?.length === 0 ? "All" : "Other"}  Photos(
            {allImages?.total === undefined ? 0 : allImages?.total})
          </h4>
        </Col>
        {/* <AllPhotosShimmer suggested={false} /> */}

        <>
          {allImages?.data?.map((data, index) => {
            const lastIndex = allImages?.data?.length - 10;
            return (
              <Col
                sm="6"
                className={`img-loading-2-item ${Style.col_image_wrap}`}
                key={index}
              >
                <div ref={lastIndex == index ? ref : null}>
                  <PhotoFolderCard
                    image={data?.src?.thumbnail_medium}
                    setIsImagePreview={setIsImagePreview}
                    setIsSlideKey={setIsSlideKey}
                    keyId={suggestedImages?.data?.length + index}
                    id={data?.id}
                    lastIndex={lastIndex}
                    setImagetype={setImagetype}
                    imagetype={"allImages"}
                    isSelected={data?.album_image[0]?.is_selected}
                    suggested={false}
                  />
                </div>
              </Col>
            );
          })}
        </>

      </Row>
    </div>
  );
}
);

export default OtherPhotos;
