import { useState } from "react";
import CommonModal from "../../Modals/CommonModal";
import Style from "../FolderView/FolderView.module.scss";
import {
  updateImageToFinalSelection,
  finalSelectionPreSelection,
} from "../../../store/slices/UserFlow/userFlowSlice";
import { useDispatch } from "react-redux";

function RemovePhotosPopup({
  photos,
  removeFromSelection,
  setRemoveFromSelection,
  setIsEditSelected,
}) {
  const dispatch = useDispatch();

  function handleConfirm() {
    dispatch(updateImageToFinalSelection());
    dispatch(finalSelectionPreSelection(false));
    setIsEditSelected(false);
    setRemoveFromSelection(false);
  }

  function handleCancel() {
    setRemoveFromSelection(false);
  }

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={removeFromSelection}
      setShowModal={setRemoveFromSelection}
      centered={true}
      className={`type-sm confirm_modal ${Style.confirm_modal}`}
      title={<h4>Remove from selection</h4>}
      content={
        <>
          <p>
            Do you want to remove {photos?.length} photos from your final
            selection?
          </p>
        </>
      }
      footer={
        <>
          <button className={`btn btn-red`} onClick={handleConfirm}>
            Confirm
          </button>
          <button className="btn btn-border-grey" onClick={handleCancel}>
            Cancel
          </button>
        </>
      }
    />
  );
}

export default RemovePhotosPopup;
