import React, { useEffect, useState } from "react";
import Icon from "../../Layouts/Icons";
import Style from "../FolderView/FolderView.module.scss";
// assuming this is a separate component for rendering icons
import { finalSelectionPageAddToSelectionAll } from "../../../store/slices/UserFlow/userFlowSlice";
import { useDispatch, useSelector } from "react-redux";

function FinalViewSelectAllButton({ isSelected, setIsSelected }) {
  const dispatch = useDispatch();
  const { finalSelection } = useSelector((state) => state.userFlow);

  useEffect(() => {
    if (finalSelection.data.length != finalSelection.selectedPhotos.length) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [finalSelection.selectedPhotos]);

  return (
    <button
      className={`btn btn-border-grey d-flex align-items-center ${Style.Select_photos_btn_mob}`}
      onClick={() => {
        if (finalSelection.data.length > finalSelection.selectedPhotos.length) {
          dispatch(finalSelectionPageAddToSelectionAll({ do_action: "add" }));
          setIsSelected(false);
        } else if (
          finalSelection.data.length === finalSelection.selectedPhotos.length
        ) {
          dispatch(
            finalSelectionPageAddToSelectionAll({ do_action: "remove" })
          );
          setIsSelected(true);
        }
      }}
    >
      <label className={`ms-auto d-flex ${Style.user_flow_suggestion_wrap}`}>
        <span
          className={`form-check-box ${!isSelected ? "checked" : ""} ${
            Style.user_flow_suggestion_checkbox
          }`}
        >
          <Icon
            color={"#fff"}
            size={13}
            icon={!isSelected ? "icon-tick" : ""}
          />
        </span>
      </label>
      Select All
    </button>
  );
}

export default FinalViewSelectAllButton;
