import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../Logic/Dimensions";
import Icon from "../../Layouts/Icons";
import Style from "./ChoosePhotoCard.module.scss";
import moment from "moment";
import { useParams } from "react-router-dom";

const ChoosePhotoCard = ({
  logo,
  image,
  eventName,
  eventDate,
  eventLocation,
  albumName,
}) => {
  const windowDimensions = useDimensions();
  const [isImainImageLoad, setIsImainImageLoad] = useState(false);
  const [isStudioLogoLoad, setIsStudioLogoLoad] = useState(false);
  let { user_token } = useParams();

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return "";
    } else {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    }
  };
  return (
    <>
      <div className={Style.choose_photo_card}>
        <Row className={Style.choose_photo_card_title_row}>
          <Col sm="6">
            <figure
              className={`ms-sm-0 ${Style.choose_logo_wrap} ${
                !isStudioLogoLoad ? "img-loading-2" : ""
              }`}
            >
              <img
                src={logo}
                alt="studio-logo"
                onLoad={() => setIsStudioLogoLoad(true)}
              />
            </figure>
          </Col>
          <Col sm="6" className="my-auto">
            <h4
              className={`text-sm-end mb-0 ${Style.choose_photo_card_title}`}
            >
              {capitalizeFirstLetter(albumName)}
            </h4>
          </Col>
        </Row>
        
          {windowDimensions.width > 992 && (
            <>
              <Row>
                <Col
                  sm="8"
                  // className={`${windowDimensions.width < 576 ? "order-2" : ""} `}
                >
                  <ul className={Style.choose_photo_card_address}>
                    <li>Event category: {eventName}</li>
                    <li>Place: {eventLocation}</li>
                  </ul>
                </Col>
                <Col
                  sm="4"
                  // className={`${windowDimensions.width < 576 ? "order-1" : ""} `}
                >
                  <p
                      className={`text-center text-sm-end ${Style.choose_photo_card_date}`}
                    >
                      Date:
                      {moment(eventDate).format("DD/MM/YYYY")}
                  </p>
                </Col>
              </Row>
            </>
          ) }
          
          
        <div className={Style.choose_photo_card_imgwrap}>
          <Row className="gx-0">
            {image?.map((data, index) => {
              return (
                <Col xs="6" key={index}>
                  <figure
                    className={`${Style.choose_photo_card_figure} ${
                      !isImainImageLoad ? "img-loading-2" : ""
                    }`}
                  >
                    <img
                      src={data?.src?.thumbnail_large}
                      alt="wedding-img"
                      onLoad={() => setIsImainImageLoad(true)}
                    />
                  </figure>
                </Col>
              );
            })}
          </Row>
        </div>
        <Row>

          {windowDimensions.width <= 992 ? (
              <>
                <Col
                  sm="8"
                  className={`text-start`}
                >
                  <ul className={Style.choose_photo_card_address}>
                    <li>Event category: {eventName}</li>
                    <li>Date: {moment(eventDate).format("DD/MM/YYYY")}</li>
                    <li>Place: {eventLocation}</li>
                  </ul>
                </Col>                
              </>
            ) : (
            <Col sm="8" className="my-auto">
              <p className={`my-1 ${Style.choose_photo_card_choose_footer}`}>
                *Choose the photos from all folders.
              </p>
            </Col>
            )}
          <Col sm="4" className={`text-center text-sm-end pt-2 pt-sm-0`}>
            <Link to={`/${user_token}/folder-view`} className="btn btn-primary">
              Open
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChoosePhotoCard;
