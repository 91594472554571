import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  postThemeData: {},
};

export const postTheme = createAsyncThunk(
  "selectedTheme/postTheme",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`preference/change-theme`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const postThemeSlice = createSlice({
  name: "selectedTheme",
  initialState,
  reducers: {},
  extraReducers: {
    [postTheme.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postTheme.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.postThemeData = action.payload.data?.result;
    },
    [postTheme.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export default postThemeSlice.reducer;
