import React from "react";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import CommonModal from "../../Modals/CommonModal";
import Style from "../FolderView/FolderView.module.scss";

const RemoveSelectedPhotosModal = ({ showModal, setShowModal, postSelectPic}) => {
  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={showModal}
      setShowModal={setShowModal}
      centered={true}
      className={`type-sm confirm_modal`}
      title={<h4>Remove Selected Photos</h4>}
      content={
        <>
          <p>Selected photos will be removed from the final selection. Are you sure you want to continue?</p>
        </>
      }
      footer={
        <>
        <button className={`btn btn-red`}>
          {postSelectPic?.status === "loading" ? (
            <InfiniteDotLoader />
          ) : (
            "Confirm"
          )}
        </button>
        <button
          className="btn btn-border-grey"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
      </>
      }
    />
  );
};

export default RemoveSelectedPhotosModal;