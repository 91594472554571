import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  plansData: [],
  testimonialData: {},
  ourClientsData: {},
  featuresOfFTPData: {},
  whatIsFPAData: {},
  status: "idle",
};

/***************************
 * HOME - PLANS API CALL *
 ***************************/

export const getPlansData = createAsyncThunk(
  "home/getPlansData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("home/plans");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * HOME - TESTIMONINALS API CALL *
 ***************************/

export const getTestimonials = createAsyncThunk(
  "home/testimonials",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("home/testimonials");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*******************************
 * HOME - OUR CLIENTS API CALL *
 *******************************/

export const getOurClients = createAsyncThunk(
  "home/ourclients",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("home/list-partner");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * HOME - FEATURES OF FPA API CALL *
 ***************************/

export const getFeaturesOfFTP = createAsyncThunk(
  "home/featuresOfFTP",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("home/features-photographers");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * HOME - WHAT IS FPA ? API CALL *
 ***************************/

export const getWhatIsFPA = createAsyncThunk(
  "home/whatIsFPA",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("home/features-photofind");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    /***************************
     * HOME LOADING PLANS DATA *
     ***************************/

    [getPlansData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getPlansData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.plansData = action.payload.result;
    },
    [getPlansData.rejected]: (state, action) => {
      state.status = "error";
    },

    /*********************************
     * HOME TESTIMONALS DATA LOADING *
     *********************************/

    [getTestimonials.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTestimonials.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.testimonialData = action.payload.result;
    },
    [getTestimonials.rejected]: (state, action) => {
      state.status = "error";
    },

    /*********************************
     * HOME GETTING CLIENT DATA *
     *********************************/

    [getOurClients.pending]: (state, action) => {
      state.status = "loading";
    },
    [getOurClients.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.ourClientsData = action.payload.result;
    },
    [getOurClients.rejected]: (state, action) => {
      state.status = "error";
    },

    /*********************************
     * HOME GETTING FEATURES OF FPA *
     *********************************/

    [getFeaturesOfFTP.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFeaturesOfFTP.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.featuresOfFTPData = action.payload.result;
    },
    [getFeaturesOfFTP.rejected]: (state, action) => {
      state.status = "error";
    },

    /*********************************
     * HOME GETTING DETAILS OF WHAT IS FPA ?*
     *********************************/

    [getWhatIsFPA.pending]: (state, action) => {
      state.status = "loading";
    },
    [getWhatIsFPA.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.whatIsFPAData = action.payload.result;
    },
    [getWhatIsFPA.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export default homeSlice.reducer;
