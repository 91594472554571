import { useDispatch, useSelector } from "react-redux";
import { getAllPhotos } from "../../store/slices/UserFlow/userFlowSlice";
import { useNavigate, useParams } from "react-router-dom";

export const useUserFlow = () => {
  const dispatch = useDispatch();
  const { currentTab, sections } = useSelector((state) => state.userFlow);
  let { user_token } = useParams();

  const navigate = useNavigate();

  const getUserFlowData = () => {
    if (sections?.["all"]?.data == undefined) {
      dispatch(getAllPhotos(user_token))
        .unwrap()
        .then((result) => {
          if (result.data.success === false) {
            navigate(`/`);
          }
        })
        .catch((err) => {
          navigate(`/`);
        });
    }
  };
  return {
    getUserFlowData,
  };
};
