import React, { useState } from "react";
import Style from "../FolderView/FolderView.module.scss";

function PhotosViewCommonButton({
  handlePreSelect,
  isPreselect,
}) {
  return (
    <button
      onClick={() => handlePreSelect()}
      className={`btn btn-border-grey ${Style.Select_photos_btn_mob}`}
    >
      {isPreselect ? "Cancel" : "Select"}
    </button>
  );
}

export default PhotosViewCommonButton;
