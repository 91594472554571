import React, { useState, useRef, forwardRef, useEffect } from "react";
import Style from "../../FolderView.module.scss";
import { Col, Row } from "react-bootstrap";
import Icon from "../../../../Layouts/Icons";
import AllPhotosShimmer from "../../Common/AllPhotosShimmer";
import PhotoFolderCard from "../../../../Cards/PhotoFolderCard";
import { useSuggestion } from "./useSuggestion";

const StudioSuggestions = forwardRef(
  (
    {
      suggestedImages,
      getSelectedImages,
      currentTab,
      status,
      setIsImagePreview,
      setIsSlideKey,
      windowDimensions,
      isSelected,
      setIsSelected,
      setImagetype,
      handleUpdateGoWithSuggestionsPopups,
      handleSelectAllAction,
    },
    ref
  ) => {


    const { lastElement, getSelectedImagesCount, totalCount, handleDeSelectAll } = useSuggestion()



    return (
      <div className={Style.user_flow_folderview_suggestion}>
        <Row>
          <Col md={12} className="px-1 px-sm-2 d-flex">
            <h4 className="h4 px-sm-1">
              Studio suggestion(
              {suggestedImages?.total === undefined
                ? 0
                : suggestedImages?.total}
              )
            </h4>
            {console.log(getSelectedImages, "pre......q1243")}
            {getSelectedImages?.preSelect && (
              <>
                {getSelectedImagesCount() == totalCount() ?

                  <label
                    className={`ms-auto d-flex ${Style.user_flow_suggestion_wrap}`}
                    onClick={() => {

                      handleDeSelectAll()
                    }}
                  >
                    <span
                      className={`form-check-box ${"checked"} ${Style.user_flow_suggestion_checkbox
                        }`}
                    >
                      <Icon
                        color={"#fff"}
                        size={13}
                        icon={"icon-tick"}
                      />
                    </span>{" "}
                    {windowDimensions.width >= 768 && "Deselect All"}
                  </label>
                  :


                  <label
                    className={`ms-auto d-flex ${Style.user_flow_suggestion_wrap}`}
                    onClick={() => {
                      handleSelectAllAction(!isSelected)
                      setIsSelected(!isSelected);
                    }}
                  >
                    <span
                      className={`form-check-box ${isSelected ? "checked" : ""} ${Style.user_flow_suggestion_checkbox
                        }`}
                    >
                      <Icon
                        color={"#fff"}
                        size={13}
                        icon={isSelected ? "icon-tick" : ""}
                      />
                    </span>{" "}
                    {windowDimensions.width >= 768 && "Select All"}
                  </label>

                }

              </>
            )}
          </Col>

          <>
            {suggestedImages?.data?.map((data, index) => {
              const lastIndex = suggestedImages?.data?.length - 10;
              return (
                <Col
                  sm="6"
                  className={`img-loading-2-item ${Style.col_image_wrap}`}
                  key={index}
                >
                  <div ref={lastIndex == index ? lastElement : null}>
                    <PhotoFolderCard
                      image={data?.src?.thumbnail_medium}
                      setIsImagePreview={setIsImagePreview}
                      setIsSlideKey={setIsSlideKey}
                      keyId={index}
                      id={data?.id}
                      lastIndex={lastIndex}
                      suggested={true}
                      setImagetype={setImagetype}
                      imagetype={"suggestions"}
                      isSelected={data?.album_image[0]?.is_selected}
                    />
                  </div>
                </Col>
              );
            })}
          </>

          {windowDimensions.width <= 768 &&
            getSelectedImages?.preSelect === false &&
            suggestedImages?.total > 0 && (
              <>
                <Col md={12} className="px-1 px-sm-2 pt-lg-4">
                  <div className="px-sm-1 w-100 d-flex pt-2">
                    <button
                      onClick={() => {
                        handleUpdateGoWithSuggestionsPopups(true);
                      }}
                      className={`btn btn-border-grey btn-border-black ms-auto ${Style.go_to_suggestion_btn}`}
                    >
                      Go with Studio suggestion
                    </button>
                  </div>
                </Col>
              </>
            )}
        </Row>
      </div>
    );
  }
);

export default StudioSuggestions;
