import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFolderview } from "../../../../Logic/UserFlow/useFolderview";
import Style from "../FolderView.module.scss";
import { useSingleKey } from "../../../../utils/KeyBoardShortCuts";
import ViewAndAddPhoto from "../../ViewAndAddPhoto";
import { useDimensions } from "../../../../Logic/Dimensions";
import StudioSuggestions from "./StudioSuggestion";
import OtherPhotos from "./OtherPhotos";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

const AllPhotos = (props) => {

  const {
    currentTab,
    sections,
    startSelectIsEnabled,
    suggestedImages,
    allImages,
    getSelectedImages,
  } = useSelector((state) => state.userFlow);

  const {
    isSelected,
    setIsSelected,
    isImagePreview,
    setIsImagePreview,
    isSlideKey,
    setIsSlideKey,
    handleSuggestedImagesLoadMore,
    handleOtherImagesLoadMore,
    handleToggleStartSelect,
    getSelectedImagesCount,
    handleUpdateGoWithSuggestionsPopups,
    handleSelectAllAction,
    handleSelectAllCheckboxAutoCheck,
    handleAddImagesToFinalSelection,
    lastElement,
  } = useFolderview();

  const handleEscape = () => {
    setIsImagePreview(false);
  };

  const windowDimensions = useDimensions();
  useSingleKey("Escape", handleEscape);

  const suggestedImageImgItemRef = useRef(null);
  const otherPhotosImageImgItemRef = useRef(null);

  const [imageType, setImagetype] = useState("");

  const observer = useRef();

  useEffect(() => {
    if (startSelectIsEnabled === true) {
      if (suggestedImages?.data.length > 0) {
        setImagetype("suggestions");
        setIsSlideKey(0);
        setIsImagePreview(true);
      } else if (suggestedImages?.data.length > 0) {
        setImagetype("allImages");
        setIsSlideKey(0);
        setIsImagePreview(true);
      }
    }
  }, [startSelectIsEnabled]);

  useEffect(() => {
    if (isImagePreview === false) {
      handleToggleStartSelect(false);
    }
  }, [isImagePreview]);



  useEffect(() => {
    handleSelectAllCheckboxAutoCheck();
  }, [allImages.data, suggestedImages.data]);

  return (
    <div className={Style.user_flow_folderview_body}>
      {suggestedImages?.data?.length > 0 && (
        <StudioSuggestions
          suggestedImages={suggestedImages}
          getSelectedImages={getSelectedImages}
          currentTab={currentTab}
          status={suggestedImages.status}
          setIsImagePreview={setIsImagePreview}
          setIsSlideKey={setIsSlideKey}
          ref={suggestedImageImgItemRef}
          windowDimensions={windowDimensions}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          setImagetype={setImagetype}
          handleUpdateGoWithSuggestionsPopups={
            handleUpdateGoWithSuggestionsPopups
          }
          handleSelectAllAction={handleSelectAllAction}
        />
      )}


      <OtherPhotos
        suggestedImages={suggestedImages}
        allImages={allImages}
        status={allImages.status}
        setIsImagePreview={setIsImagePreview}
        setIsSlideKey={setIsSlideKey}
        // ref={otherPhotosImageImgItemRef}
        setImagetype={setImagetype}
        ref={lastElement}
      />


      {
        isImagePreview === true ? (
          <>
            <ViewAndAddPhoto
              slideData={[...suggestedImages?.data, ...allImages?.data]

              }
              setIsImagePreview={setIsImagePreview}
              activeSlideIndex={isSlideKey}
              getSelectedImagesCount={getSelectedImagesCount}
              handleAddImagesToFinalSelection={handleAddImagesToFinalSelection}
            />
          </>
        ) : (
          <></>
        )
      }
    </div >
  );
};

export default AllPhotos;


{/* <div style={{
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%,-50%)"
}
}
>
  <InfiniteDotLoader type={"project"} />
</div> */}