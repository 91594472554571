import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFolderview } from "../../../../Logic/UserFlow/useFolderview";
import { useSingleKey } from "../../../../utils/KeyBoardShortCuts";
import PhotoFolderCard from "../../../Cards/PhotoFolderCard";
import ImagePreview from "../../ImagePreview";
import ViewAndAddPhoto from "../../ViewAndAddPhoto";
import Style from "../FolderView.module.scss";

const SelectedPhotos = () => {
  const { currentTab, sections, status, finalSelection } = useSelector(
    (state) => state.userFlow
  );
  const {
    setIsImagePreview,
    setIsSlideKey,
    isImagePreview,
    isSlideKey,
    redirectToFolderView,
    getFinalSelectedImagesCount,
  } = useFolderview();

  const handleEscape = () => {
    setIsImagePreview(false);
  };

  const navigate = useNavigate();

  useSingleKey("Escape", handleEscape);

  return (
    <div
      className={`
      ${Style.user_flow_folderview_body}
      ${
        (finalSelection?.data?.length === 0 && status === "succeed") ||
        (finalSelection?.data?.length === undefined && status === "succeed")
          ? "d-flex"
          : ""
      }
    `}
    >
      {status === "succeed" ? (
        <>
          {finalSelection?.data?.length > 0 ? (
            <Row className={Style.user_flow_folderview_selected_photos}>
              {finalSelection?.data?.map((data, index) => {
                return (
                  <Col
                    sm="6"
                    className={`img-loading-2-item ${Style.col_image_wrap}`}
                    key={index}
                  >
                    <PhotoFolderCard
                      image={data?.src?.thumbnail_medium}
                      imagePreview={setIsImagePreview}
                      setIsSlideKey={setIsSlideKey}
                      setImagetype={() => {}}
                      keyId={index}
                      id={data?.id}
                      setIsImagePreview={setIsImagePreview}
                      isSelected={
                        finalSelection?.selectedPhotos.includes(data?.id) ===
                        true
                          ? 1
                          : 0
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <>
              <div className={`text-center ${Style.no_image_msg}`}>
                <h4 className="h4">No photos has been selected.</h4>
                <p>Click below button for adding photos into the album</p>
                <button
                  onClick={() => {
                    redirectToFolderView();
                  }}
                  className={`btn btn-border-primary fw-bold`}
                >
                  Add photos
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <Row>
            {(() => {
              let rows = [];
              for (let i = 0; i < 15; i++) {
                rows.push(
                  <Col
                    sm="6"
                    className={`img-loading-2-item ${Style.col_image_wrap}`}
                    key={i}
                  >
                    <PhotoFolderCard keyId={i} />
                  </Col>
                );
              }
              return rows;
            })()}
          </Row>
        </>
      )}

      {isImagePreview === true ? (
        <ViewAndAddPhoto
          getSelectedImagesCount={getFinalSelectedImagesCount}
          slideData={finalSelection.data}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={isSlideKey}
          page={"finalSection"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectedPhotos;
