import { forwardRef, useEffect, useState } from "react";
import Icon from "../../Layouts/Icons";
import Style from "./PhotoFolderCard.module.scss";
import { useDimensions } from "../../../Logic/Dimensions";
import { useDispatch, useSelector } from "react-redux";
import { addImageHandler,finalSelectionPageAddToSelection } from "../../../store/slices/UserFlow/userFlowSlice";

const PhotoFolderCard = ({
  image,
  setIsImagePreview,
  setIsSlideKey,
  keyId,
  id,
  lastIndex,
  type,
  suggested,
  setImagetype,
  imagetype,
  isSelected,
}) => {
  const windowDimensions = useDimensions();
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);
  const { getSelectedImages, currentTab,finalSelection } = useSelector(
    (state) => state.userFlow
  );
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`load-animaiton ${Style.photo_folder_card} ${
          isSelectionOpen ? "" : "img-loading img-loading-2"
        }`}
      >
        <figure>
          {suggested && (
            <>
              <span className={Style.photo_folder_card_imgsuggested}></span>
            </>
          )}
          {image && (
            <>
              <img src={image} alt="" onLoad={() => setIsSelectionOpen(true)} />
            </>
          )}
        </figure>
        {windowDimensions.width >= 576 ? (
          <>
            {/* {!getSelectedImages?.preSelect && ( */}
            <button
              className="btn btn-border-grey"
              onClick={() => {
                setIsImagePreview(true);
                setIsSlideKey(keyId);
                console.log("keyId",keyId);
                setImagetype(imagetype);
              }}
            >
              View
            </button>
            {/* )} */}
          </>
        ) : (
          <>
            {!getSelectedImages?.preSelect && (
              <span
                className={Style.photo_folder_card_full_view}
                onClick={() => {
                  setIsImagePreview(true);
                  setIsSlideKey(keyId);
                }}
              ></span>
            )}
          </>
        )}

        {( (getSelectedImages?.preSelect == true && currentTab !== "Selected") || (finalSelection?.preSelect == true && currentTab == "Selected") ) && (
          <span
            onClick={() => {
              if (currentTab === "Selected") {
                dispatch(finalSelectionPageAddToSelection(id));
              } else {
                dispatch(addImageHandler(id));
              }
            }}
            className={`form-check-box ${
             // getSelectedImages?.["preSelectedImage"]?.includes(id) ||
              isSelected === 1
                ? "checked"
                : ""
            }`}
          >
            <Icon
              color={"#fff"}
              size={13}
              icon={
               // getSelectedImages?.["preSelectedImage"]?.includes(id) ||
                isSelected === 1
                  ? "icon-tick"
                  : ""
              }
            />
          </span>
        )}
      </div>
    </>
  );
};

export default PhotoFolderCard;
