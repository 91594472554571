import NotFoundPage from "../pages/NotFoundPage";
import StyleGuidePage from "../pages/StyleGuidePage";
import TOCPage from "../pages/TOCPage";
import UserFlowPage from "../pages/UserFlowPage";
import UserFlowFolderViewPage from "../pages/UserFlowFolderViewPage";
import PlansPage from "../pages/PlansPage";
import ProjectPlansPage from "../pages/ProjectPlansPage";
import ContactPage from "../pages/ContactPage";
import LegalPages from "../pages/LegalPages";
import AllPhotosPage from "../pages/AllPhotosPage";
import SelectedPhotosPage from "../pages/SelectedPhotosPage";

const MainRoutes = [
  {
    name: "UserFlow",
    path: "/:user_token",
    element: (
      <>
        <UserFlowPage />
      </>
    ),
  },
  {
    name: "UserFlow Folder View",
    path: "/:user_token/folder-view",
    element: (
      <>
        <UserFlowFolderViewPage />
      </>
    ),
    Children: [
      {
        name: "All Photos",
        path: "",
        element: <AllPhotosPage />,
      },
      {
        name: "Selected Photos",
        path: "selected",
        element: <SelectedPhotosPage />,
      },
    ],
  },

  {
    name: "404",
    path: "*",
    element: (
      <>
        <NotFoundPage />
      </>
    ),
  },
];

export default MainRoutes;
