import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  currentTab: "all",
  getFirstFlowData: {},
  userData: {},
  linkStatusData: "",
  sections: {},
  startSelectIsEnabled: false,
  getSelectedImages: { preSelectedImage: [], preSelect: false },
  getSelectedImages: {
    preSelectedImage: [],
    preSelect: false,
    goWithSuggestionsPopups: false,
  },
  suggestedImages: {
    data: []
  },
  allImages: {
    data: []
  },
  finalSelection: { data: [], preSelect: false, selectedPhotos: [] },
};

export const fetchSuggestedImages = createAsyncThunk(
  "images/fetchSuggestedImages",
  async (uuid, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/share-album?uuid=${uuid}&page=1&filter=1`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suggestedImagesLoadMore = createAsyncThunk(
  "userFlow/suggestedImagesLoadMore",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/share-album?uuid=${params?.token}&page=${params?.page}&filter=1`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOtherImages = createAsyncThunk(
  "images/fetchOtherImages",
  async (uuid, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/share-album?uuid=${uuid}&page=1&filter=0`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const otherImagesLoadMore = createAsyncThunk(
  "userFlow/otherImagesLoadMore",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/share-album?uuid=${params?.token}&page=${params?.page}&&filter=0`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllPhotos = createAsyncThunk(
  "userFlow/getAllPhotos",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/share-album?uuid=${params}&page=1`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postSelectPic = createAsyncThunk(
  "userFlow/postSelectPic",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/selected`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userFlowSlice = createSlice({
  name: "userFlow",
  initialState,
  reducers: {
    addImageHandler: (state, action) => {
      if (state.suggestedImages?.data != undefined) {
        const index1 = state?.suggestedImages?.data?.findIndex(
          (item) => item.id === action.payload
        );
        if (index1 != -1) {
          state.suggestedImages.data[index1].album_image[0].is_selected =
            state.suggestedImages.data[index1].album_image[0].is_selected == 1
              ? 0
              : 1;
        }
      }
      if (state.allImages?.data != undefined) {
        const index2 = state.allImages?.data?.findIndex(
          (item) => item.id === action.payload
        );
        if (index2 != -1) {
          state.allImages.data[index2].album_image[0].is_selected =
            state.allImages.data[index2].album_image[0].is_selected == 1
              ? 0
              : 1;
        }
      }
    },

    addImageToFinalSelection: (state, action) => {
      state.finalSelection.data = action.payload;
    },

    updateAllSelectedImages: (state, action) => {
      if (state.suggestedImages?.data != undefined) {
        state?.suggestedImages?.data?.map((element, index) => {
          state.suggestedImages.data[index].album_image[0].is_selected = 1;
        });
      }

      if (state.allImages?.data != undefined) {
        state?.allImages?.data?.map((element, index) => {
          state.allImages.data[index].album_image[0].is_selected = 0;
        });
      }
    },

    finalSelectionPreSelection: (state, action) => {
      state.finalSelection.preSelect = action.payload;
    },

    updatePreselect: (state, action) => {
      state.getSelectedImages.preSelect = action.payload;
    },

    currentTabAction: (state, action) => {
      state.currentTab = action.payload;
    },

    openImageModal: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentImageOpen: action.payload,
      };
    },

    selectedPhotos: (state, action) => {
      state.sections["Selected"] = {
        data: [...action.payload],
      };
    },

    clearSelectedPhotos: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {},
        preSelectedImage: [],
      };
    },

    toggleStartSelect: (state, action) => {
      state.startSelectIsEnabled = action.payload;
    },

    finalSelectionPageAddToSelection: (state, action) => {
      if (
        current(state.finalSelection?.selectedPhotos).includes(
          action.payload
        ) === false
      ) {
        state.finalSelection.selectedPhotos = [
          ...current(state.finalSelection?.selectedPhotos),
          ...[action.payload],
        ];
      } else if (
        current(state.finalSelection?.selectedPhotos).includes(
          action.payload
        ) === true
      ) {
        const updated = state.finalSelection?.selectedPhotos?.filter((item) => {
          return item != action.payload;
        });

        state.finalSelection.selectedPhotos = updated;
      }
    },
    finalSelectionPageAddToSelectionAll: (state, action) => {
      if (action.payload.do_action === "add") {
        var allIDs = state.finalSelection.data.map(function (item) {
          return item.id;
        });
        state.finalSelection.selectedPhotos = allIDs;
      } else if (action.payload.do_action === "remove") {
        state.finalSelection.selectedPhotos = [];
      }
    },

    updateImageToFinalSelection: (state, action) => {
      const updatedData = state.finalSelection.data.filter((item) => {
        return state.finalSelection.selectedPhotos.includes(item.id) === false;
      });
      state.finalSelection.selectedPhotos = [];
      state.finalSelection.data = updatedData;
    },

    updateGoWithSuggestionsPopups: (state, action) => {
      state.getSelectedImages.goWithSuggestionsPopups = action.payload;
    },
    selectAllButton: (state, action) => {
      if (state.suggestedImages?.data != undefined) {
        state?.suggestedImages?.data?.map((element, index) => {
          state.suggestedImages.data[index].album_image[0].is_selected =
            action.payload === true ? 1 : 0;
        });
      }
      if (state.allImages?.data != undefined) {
        state.allImages.data?.map((element, index) => {
          state.allImages.data[index].album_image[0].is_selected =
            action.payload === true ? 1 : 0;
        });
      }
    },
  },
  extraReducers: {
    [fetchSuggestedImages.pending]: (state, action) => {
      state.suggestedImages.status = "loading";
    },
    [fetchSuggestedImages.fulfilled]: (state, action) => {

      state.suggestedImages = { ...action.payload.result.images, ...{ data: action.payload.result.images.data?.map(v => ({ ...v, suggestedImages: true })) } };
      state.suggestedImages.status = "succeed";
    },
    [fetchSuggestedImages.rejected]: (state, action) => {
      state.suggestedImages.status = "error";
    },

    [fetchOtherImages.pending]: (state, action) => {
      state.allImages.status = "loading";
    },
    [fetchOtherImages.fulfilled]: (state, action) => {
      state.allImages = action.payload.result.images;
      state.allImages.status = "succeed";
    },
    [fetchOtherImages.rejected]: (state, action) => {
      state.allImages.status = "error";
    },

    [getAllPhotos.pending]: (state, action) => {
      state.status = "loading";
      state.mainPageStatus = "loading";
      state.message = "";
    },
    [getAllPhotos.fulfilled]: (state, action) => {
      if (action.payload.data.success == true) {
        delete state.invalidURL;
        state.status = "succeed";
        state.mainPageStatus = "succeed";
        state.sections["all"] = action.payload.data.result.images;

        state.userData = action.payload.data.result.user;

        state.linkStatusData = action.payload.data.result;
      } else {
        state.invalidURL = true;
        state.mainPageStatus = "error";
        state.status = "error";
      }
    },
    [getAllPhotos.rejected]: (state, action) => {
      state.mainPageStatus = "error";
      state.status = "error";
    },

    [postSelectPic.pending]: (state, action) => {
      state.finalSelection.status = "loading";
      state.message = "";
    },
    [postSelectPic.fulfilled]: (state, action) => {
      state.finalSelection.status = "succeed";
    },
    [postSelectPic.rejected]: (state, action) => {
      state.finalSelection.status = "error";
    },

    [otherImagesLoadMore.pending]: (state, action) => {
      state.allImages.status = "loading";
    },
    [otherImagesLoadMore.fulfilled]: (state, action) => {
      state.allImages.status = "succeed";
      state.allImages.data = [
        ...state.allImages.data,
        ...action.payload.data.result.images.data,
      ];

      state.allImages = {
        ...state.allImages,
        current_page: action?.payload?.data?.result?.images?.current_page,
      };
    },
    [otherImagesLoadMore.rejected]: (state, action) => {
      state.allImages.status = "error";
    },

    [suggestedImagesLoadMore.pending]: (state, action) => {
      state.suggestedImages.status = "loading";
      state.message = "";
    },

    [suggestedImagesLoadMore.fulfilled]: (state, action) => {
      console.log(action.payload.data.result.images.data, 'hey dayta');
      state.suggestedImages.status = "succeed";
      state.suggestedImages.data = [
        ...state.suggestedImages.data,
        ...action.payload.data.result.images.data

      ];
      // ...{...action.payload.result.images,...{data:action.payload.result.images.data?.map(v => ({...v, suggestedImages: true}))}}

      state.suggestedImages = {
        ...state.suggestedImages,
        current_page: action?.payload?.data?.result?.images?.current_page,
      };
    },
    [suggestedImagesLoadMore.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const {
  updatePreselect,
  addImageHandler,
  currentTabAction,
  openImageModal,
  selectedPhotos,
  clearSelectedPhotos,
  toggleStartSelect,
  addImageToFinalSelection,
  finalSelectionPreSelection,
  pushAlreadySelectedItemToPreSelectedImage,
  finalSelectionPageAddToSelection,
  finalSelectionPageAddToSelectionAll,
  updateImageToFinalSelection,
  updateGoWithSuggestionsPopups,
  selectAllButton,
  updateAllSelectedImages,
} = userFlowSlice.actions;

export default userFlowSlice.reducer;
