import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  languageData: [],
  changePasswordData: "",
  staffViewData: [],
  deleteStaffData: {},
};

export const getPreferenceLanguage = createAsyncThunk(
  "preferenceList/getPreferenceLanguage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/preference/languages");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPreferenceLanguage = createAsyncThunk(
  "preferenceList/postPreferenceLanguage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/preference/change-language", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "preferenceList/changePassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/change-password", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addStaff = createAsyncThunk(
  "preferenceList/addStaff",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/staff/save-staff", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewStaff = createAsyncThunk(
  "preferenceList/viewStaff",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/staff/list-staff?page=1");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMoreStaff = createAsyncThunk(
  "preferenceList/loadMoreStaff",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/staff/list-staff?page=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStaff = createAsyncThunk(
  "preferenceList/deleteStaff",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(`/staff/delete-staff`, { params });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const preferenceListSlice = createSlice({
  name: "preferenceList",
  initialState,
  reducers: {
    updateStaffDetailsHandler: (state, action) => {
      let staffIndex = state.staffViewData.data.findIndex(
        (item) => item?.id == action.payload.id
      );
      state.staffViewData.data[staffIndex] = action.payload;
    },

    deleteStaffHandler: (state, action) => {
      state.staffViewData.data = state.staffViewData.data.filter(
        (item) => item?.id != action.payload
      );
    },
  },
  extraReducers: {
    [getPreferenceLanguage.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getPreferenceLanguage.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.languageData = action.payload.data.result;
    },
    [getPreferenceLanguage.rejected]: (state, action) => {
      state.status = "error";
    },

    [postPreferenceLanguage.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postPreferenceLanguage.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [postPreferenceLanguage.rejected]: (state, action) => {
      state.status = "error";
    },

    [changePassword.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [changePassword.rejected]: (state, action) => {
      state.status = "error";
    },

    [addStaff.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [addStaff.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [addStaff.rejected]: (state, action) => {
      state.status = "error";
    },

    [viewStaff.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [viewStaff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.staffViewData = action.payload.data.result;
    },
    [viewStaff.rejected]: (state, action) => {
      state.status = "error";
    },

    [loadMoreStaff.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [loadMoreStaff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.staffViewData.data = [
        ...state.staffViewData.data,
        ...action.payload.data.result.data,
      ];
      state.staffViewData = {
        ...state.staffViewData,
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [loadMoreStaff.rejected]: (state, action) => {
      state.status = "error";
    },

    [deleteStaff.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [deleteStaff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.deleteStaffData = action.payload.data.result;
    },
    [deleteStaff.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const { updateStaffDetailsHandler, deleteStaffHandler } =
  preferenceListSlice.actions;
export default preferenceListSlice.reducer;
