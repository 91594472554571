import React from "react";
import Style from "../FolderView/FolderView.module.scss";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import Icon from "../../Layouts/Icons";
import CommonModal from "../../Modals/CommonModal";

function ConfirmSelectionModal(props) {
  const { 
    isConfirmSelectionModal,
    setIsConfirmSelectionModal,
    isAPIloading,
    handleConfirm,
    setRemoveFromSelection,
  } = props;

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={isConfirmSelectionModal}
      setShowModal={setIsConfirmSelectionModal}
      centered={true}
      className={`type-sm confirm_modal ${Style.confirm_modal}`}
      title={
        <>
          <span className={Style.Confirm_Selection_icon}>
            <Icon size={25} icon={"icon-tick"} color={"white"} />
          </span>
          <h4>Confirm selection</h4>
        </>
      }
      content={
        <>
          <p>
            You have successfully selected the photos, click confirm to
            complete your selection
          </p>
        </>
      }
      footer={
        <>
          <button
            className={`btn btn-primary ${
              isAPIloading === "loading" && "btn-loading"
            }`}
            onClick={() => {
              handleConfirm();
            }}
          >
            {isAPIloading === "loading" ? (
              <InfiniteDotLoader />
            ) : (
              "Confirm"
            )}
          </button>
          <button
            className="btn btn-border-grey"
            onClick={() => setIsConfirmSelectionModal(false)}
          >
            Cancel
          </button>
        </>
      }
    />
  );
}

export default ConfirmSelectionModal;