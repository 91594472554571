import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  currentPage: 0,
  totalPages: "",
  order: "asc",
  currentTab: "all",
  currentFolderDetails: "",
  sections: {},
  activeUploadIndex: 0,
  activeUploadPercent: 0,
  isSingleImageUpload: false,
  openUploadModal: false,
  openMiniBox: false,
  thumbs: [],
};

export const getGalleryImages = createAsyncThunk(
  "gallery/getGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customFetchImages = createAsyncThunk(
  "gallery/customFetchImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFolderDetails = createAsyncThunk(
  "gallery/getFolderDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGalleryImages = createAsyncThunk(
  "gallery/deleteGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };
      const response = await api.post(`${params.url}`, params.formdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    currentTabHandler: (state, action) => {
      state.currentTab = action.payload;
    },
    openPreSelectionhandler: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {
          preSelect: true,
        },
      };
    },

    selectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    unSelectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [
        ...new Array(0).keys(),
      ];
    },

    selectSingleImage: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    deSelectSingleImage: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    closePreSelect: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        ...state.sections[state.currentTab]["currentAction"],
        preSelect: false,
      };
    },

    closeFilterActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentAction"] = {};
    },

    deleteActionSuccess: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentAction"] = {};
      state.sections[state.currentTab]["data"] = action.payload;
    },

    addToThumbs: (state, action) => {
      state.thumbs = [...state.thumbs, action.payload];
    },

    clearThumbs: (state, action) => {
      state.thumbs = [];
      state.activeUploadPercent = 0;
    },

    updateActiveUploadPercentage: (state, action) => {
      state.activeUploadPercent = action.payload;
    },

    updateUploadedImage: (state, action) => {
      state.sections["all"].data = [
        action.payload.data,
        ...state.sections["all"].data,
      ];
    },

    updateActiveIndex: (state, action) => {
      state.activeUploadIndex = action.payload;
    },

    uploadModalAction: (state, action) => {
      state.openUploadModal = action.payload;
    },

    openMinimizedBox: (state, action) => {
      state.openMiniBox = action.payload;
    },

    callFunction: (state, action) => {
      action.payload(state, action);
    },

    openDeleteImagesPopup: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        preSelect: true,
        deleteWarningPopup: action.payload,
      };
    },
  },
  extraReducers: {
    [getGalleryImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.status = "success";
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        ...action?.payload?.data?.result,
      };
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    /*******************
     * PAGINATION CODE *
     *******************/

    [customFetchImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [customFetchImages.fulfilled]: (state, action) => {
      let imgIds = action?.payload?.data?.result.data.map(
        (item, index) => item.id
      );
      state.status = "succeeded";

      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action?.payload?.data?.result.data,
      ];
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [customFetchImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getFolderDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFolderDetails.fulfilled]: (state, action) => {
      state.status = "success";
      state.currentFolderDetails = action?.payload?.data?.result;
    },
    [getFolderDetails.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteGalleryImages.pending]: (state, action) => {},
    [deleteGalleryImages.fulfilled]: (state, action) => {},
    [deleteGalleryImages.rejected]: (state, action) => {},
  },
});

export const {
  currentTabHandler,
  openPreSelectionhandler,
  closePreSelect,
  selectAllImages,
  unSelectAllImages,
  addToThumbs,
  clearThumbs,
  updateActiveUploadPercentage,
  updateUploadedImage,
  updateActiveIndex,
  callFunction,
  uploadModalAction,
  openMinimizedBox,
  selectSingleImage,
  deSelectSingleImage,
  closeFilterActions,
  openDeleteImagesPopup,
  deleteActionSuccess,
} = gallerySlice.actions;

export default gallerySlice.reducer;
