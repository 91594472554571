import React from "react";
import CommonModal from "../../Modals/CommonModal";
import Style from "../FolderView/FolderView.module.scss";

function StartSelectionButton({ setIsStartSelect, clickAction,handlePreSelect }) {
  return (
    <button
      className={`btn btn-primary ms-auto ${Style.user_flow_start_seletion}`}
      onClick={() => {
        clickAction(true);
        setIsStartSelect(true);
        setTimeout(() => {
          handlePreSelect();
        }, 1000);
      }}
    >
      Start selecting
    </button>
  );
}

export default StartSelectionButton;
