import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";
import { useDimensions } from "../../../Logic/Dimensions";
import { useHeader } from "../../../Logic/Header/useHeader";
import HamburgerMenu from "./HamburgerMenu";
import HeaderAddress from "./HeaderAddress";
import HeaderLogo from "./HeaderLogo";
import LoggedInHeaderRight from "./LoggedInHeaderRight";
import LoggedInMenu from "./LoggedInMenu";
import LoggedOutHeaderRight from "./LoggedOutHeaderRight";
import LoggedOutMenu from "./LoggedOutMenu";
import Style from "./MainHeader.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const MainHeader = () => {
  let { user_token } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const { userProfileData } = useSelector((state) => state.userData);

  const [userFlowPages, setUserFlowPages] = useState(false);

  useEffect(() => {
    if (
      userProfileData?.theme_class_name &&
      location.pathname.startsWith("/dashboard")
    ) {
      document.body.setAttribute(
        "data-theme",
        userProfileData?.theme_class_name
      );
    } else {
      document.body.removeAttribute("data-theme", "");
    }
  }, [userProfileData?.theme_class_name, location.pathname]);

  useEffect(() => {
    setUserFlowPages(
      location.pathname === `/${user_token}` ||
        location.pathname === `/${user_token}/folder-view`
        ? true
        : false
    );
  }, [location.pathname]);

  return (
    <header
      className={`${Style.MainHeader} ${
        isMenuOpen ? Style.MainHeader__menuOpen : ""
      } ${
        location.pathname.startsWith("/dashboard") ? Style.DashboardLayout : ""
      }`}
    >
      <Container
        className={`${
          location.pathname.startsWith("/dashboard") ? "container-lg" : ""
        }`}
      >
        <div
          className={`${Style.MainHeader__wrap} ${
            userFlowPages ? Style.MainHeader__userFlowPages : ""
          }`}
        >
          <HeaderLogo userProfileData={userProfileData} />
        </div>
      </Container>
    </header>
  );
};

export default MainHeader;

let menuList = [
  {
    name: "Projects",
    iconName: "icon-projects",
    path: "/dashboard",
  },
  {
    name: "Preference",
    iconName: "icon-settings",
    path: "/dashboard/preference",
  },
];
