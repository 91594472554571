import { useEffect, useRef, useState } from "react";
import Icon from "../../../Layouts/Icons";
import Style from "../ViewAndAddPhoto.module.scss";
import { addImageHandler } from "../../../../store/slices/UserFlow/userFlowSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewAndAddPhotoSlide = ({
  isStartSlider,
  type,
  data,
  page,
  suggested,
  index,
  isNewAddedImage,
  setIsNewAddedImage,
  swiperNext,
  handleAddImagesToFinalSelection
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isUndoNoti, setIsUndoNoti] = useState(null);
  const dispatch = useDispatch();
  let timeout = useRef(null);
  const { getSelectedImages, suggestedImages, allImages } = useSelector(
    (state) => state.userFlow
  );

  function handleSelectClick() {
    setIsSelected(!isSelected);
    setIsUndoNoti(false);
    clearTimeout(timeout.current);
    setTimeout(() => {
      setIsUndoNoti(true);
    }, 100);
    timeout.current = setTimeout(() => {
      setIsUndoNoti(false);
    }, 5000);
    setIsNewAddedImage(isSelected ? isNewAddedImage - 1 : isNewAddedImage + 1);
    return () => {
      clearTimeout(timeout.current);
    };
  }

  function handleUndoClick() {
    setIsSelected(!isSelected);
    setIsUndoNoti(false);
    setIsNewAddedImage(isSelected ? isNewAddedImage - 1 : isNewAddedImage + 1);
  }
  useEffect(() => {
    if (isSelected) {
      swiperNext()
    }
  }, [isSelected]);
  
  return (
    <>
      <figure
        className={`${
          type == "normal-lightbox"
            ? ""
            : data.selected == true
            ? "img_selectd"
            : ""
        } 
          ${suggested ? Style.image_selected : ""}
          `}
      >
        {isStartSlider - 8 <= index && isStartSlider + 8 >= index ? (
          <img src={data?.src?.thumbnail_large} alt="client-logo" />
        ) : (
          <></>
        )}
        
      </figure>

      <div className={Style.image_preview_footer}>
        <p className={Style.image_preview_footer_img_name}>
          {data?.original_name}
        </p>
        {page != "finalSection" && (
          <div className={Style.image_preview_footer_container}>
            <div className={`${Style.image_preview_footer_wrap} text-center`}>
              <button
                onClick={() => {
                  handleSelectClick();
                  dispatch(addImageHandler(data.id));
                }}
                className={`btn ${
                  isSelected ? "btn-border-white" : "btn-primary"
                }`}
              >
                {" "}
                {[...suggestedImages?.data, ...allImages?.data].findIndex(
                  (item) =>
                    item.album_image[0].is_selected == 1 && item.id == data.id
                ) == -1
                  ? (`Add`)
                  : "Remove"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ViewAndAddPhotoSlide;
