import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  toggleStartSelect,
  postSelectPic,
  fetchSuggestedImages,
  suggestedImagesLoadMore,
  fetchOtherImages,
  otherImagesLoadMore,
  addImageToFinalSelection,
  finalSelectionPreSelection,
  updatePreselect,
  currentTabAction,
  updateGoWithSuggestionsPopups,
  selectAllButton,
  updateAllSelectedImages,
} from "../../store/slices/UserFlow/userFlowSlice";

import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { string } from "yup/lib/locale";
import { object } from "yup";
import { toast } from "react-toastify";
import useObserver from "../../utils/hooks/useObserver";

export const useFolderview = () => {
  let { user_token } = useParams();
  const [isSelectionModal, setIsSelectionModal] = useState(false);
  const [isThanksModal, setIsThanksModal] = useState();
  const [isConfirmSelectionModal, setIsConfirmSelectionModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const {
    currentTab,
    sections,
    userData,
    suggestedImages,
    startSelectIsEnabled,
    getSelectedImages,
    finalSelection,
    allImages,

  } = useSelector((state) => state.userFlow);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSlideKey, setIsSlideKey] = useState(0);
  const [isImagePreview, setIsImagePreview] = useState(false);

  const backHome = () => {
    
    navigate(`/${user_token}/folder-view/`);
    dispatch(currentTabAction("all"));
    dispatch(updatePreselect(false));
  };



  useEffect(() => {
    if (location.pathname == `/${user_token}/folder-view/selected`) {
      dispatch(currentTabAction("Selected"));
    }
  }, [])




  const handleConfirm = async () => {
    let tempData = finalSelection?.data?.map((data) => {
      return {
        image_id: data?.id,
        album_id: data?.album_id,
      };
    });

    const payload = { album: tempData, email: userData?.email };

    const resultAction = await dispatch(postSelectPic(payload));
    if (postSelectPic.fulfilled.match(resultAction)) {
      setIsConfirmSelectionModal(false);
      setIsThanksModal(true);
    }
  };

  const handleFetchSuggestedImages = async () => {
    const resultAction = await dispatch(fetchSuggestedImages(user_token));
    if (fetchSuggestedImages.fulfilled.match(resultAction)) {
      loadMore(resultAction.payload.result.images)
    }
  };

  const loadMore = async (Images) => {
    if (Images?.current_page < Images?.last_page) {
      let params = {
        token: user_token,
        page: Images?.current_page + 1,
      };
      const resultAction = await dispatch(suggestedImagesLoadMore(params));
      if (suggestedImagesLoadMore.fulfilled.match(resultAction)) {
        loadMore(resultAction.payload.result.images)
      }
    }
  }



  const handleFetchAllImages = async () => {
    const resultAction = await dispatch(fetchOtherImages(user_token));
    if (fetchOtherImages.fulfilled.match(resultAction)) {
      loadMoreAll(resultAction.payload.result.images)
    }
  };

  const loadMoreAll = async (Images) => {
    if (Images?.current_page < Images?.last_page) {
      let params = {
        token: user_token,
        page: Images?.current_page + 1,
      };
      const resultAction = await dispatch(otherImagesLoadMore(params));
      if (otherImagesLoadMore.fulfilled.match(resultAction)) {
        loadMoreAll(resultAction.payload.result.images)
      }
    }
  }

  const handleToggleStartSelect = (status) => {
    dispatch(toggleStartSelect(status));
  };

  const handleAddImagesToFinalSelection = () => {
    const suggestedImagesWasSelected = suggestedImages?.data.filter(
      (element) => {
        return element.album_image[0].is_selected == 1;
      }
    );

    const allImagesWasSelected = allImages?.data.filter((element) => {
      return element.album_image[0].is_selected == 1;
    });

    dispatch(
      addImageToFinalSelection([
        ...suggestedImagesWasSelected,
        ...allImagesWasSelected,
      ])
    );
  };

  const handleGoWithStudioSuggestions = () => {
    dispatch(updateAllSelectedImages());
    dispatch(addImageToFinalSelection(suggestedImages?.data));

    navigate(`/${user_token}/folder-view/selected`);
    dispatch(currentTabAction("Selected"));
  };

  const handleFinalEdit = (state) => {
    navigate(`/${user_token}/folder-view/`);
    dispatch(currentTabAction("all"));
  };

  const getSelectedImagesCount = () => {
    const simages =
      Array.isArray(suggestedImages?.data) === true
        ? suggestedImages?.data
        : [];

    const oimages =
      Array.isArray(allImages?.data) === true ? allImages?.data : [];

    const images = [...simages, ...oimages].filter(
      (item) => item.album_image[0].is_selected == 1
    );
    return images.length;
  };

  const getFinalSelectedImagesCount = () => {
    return finalSelection.data.length;
  };

  const redirectToFolderView = () => {
    navigate(`/${user_token}/folder-view`);
    dispatch(currentTabAction("all"));
  };

  const handlePreSelect = () => {
    if (getSelectedImages?.preSelect) {
      dispatch(updatePreselect(false));
    } else {
      dispatch(updatePreselect(true));
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return "";
    } else {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    }
  };

  const handleUpdateGoWithSuggestionsPopups = (status) => {
    dispatch(updateGoWithSuggestionsPopups(status));
  };

  const handleSelectAllAction = (status) => {
    dispatch(selectAllButton(status));
  };

  const handleSelectAllCheckboxAutoCheck = () => {
    const index1 = suggestedImages?.data?.findIndex(
      (item) => item.album_image[0].is_selected === 0
    );

    if (index1 != -1) {
      setIsSelected(false);
    }

    const index2 = allImages?.data?.findIndex(
      (item) => item.album_image[0].is_selected === 0
    );

    if (index2 != -1) {
      setIsSelected(false);
    }
    if (index1 === -1 && index2 === -1) {
      setIsSelected(true);
    }
  };

  return {
    isSelected,
    setIsSelected,
    isSlideKey,
    setIsSlideKey,
    isImagePreview,
    setIsImagePreview,
    isSelectionModal,
    setIsSelectionModal,
    isThanksModal,
    setIsThanksModal,
    handleConfirm,
    handleFetchSuggestedImages,
    // handleSuggestedImagesLoadMore,
    handleFetchAllImages,
    // handleOtherImagesLoadMore,
    handleToggleStartSelect,
    handleAddImagesToFinalSelection,
    handleFinalEdit,
    isConfirmSelectionModal,
    setIsConfirmSelectionModal,
    getSelectedImagesCount,
    redirectToFolderView,
    handlePreSelect,
    capitalizeFirstLetter,
    getFinalSelectedImagesCount,
    handleGoWithStudioSuggestions,
    handleUpdateGoWithSuggestionsPopups,
    handleSelectAllAction,
    handleSelectAllCheckboxAutoCheck,
    backHome,
    // lastElement
  };
};
