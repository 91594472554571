import React from "react";
import Style from "../FolderView/FolderView.module.scss";

function DesktopSubmitButton({ onClick }) {
  return (
    <button
      className={`btn btn-primary ms-auto ${Style.Select_photos_btn_desktop}`}
      onClick={onClick}
    >
      Submit
    </button>
  );
}

export default DesktopSubmitButton;