
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectAllButton, suggestedImagesLoadMore } from "../../../../../store/slices/UserFlow/userFlowSlice";
import useObserver from "../../../../../utils/hooks/useObserver";

export const useSuggestion = () => {
    const { suggestedImages, allImages } = useSelector((state) => state.userFlow);
    const dispatch = useDispatch()
    let { user_token } = useParams();


    const handleSuggestedImagesLoadMore = () => {
        let params = {
            token: user_token,
            page: suggestedImages?.current_page + 1,
        };
        dispatch(suggestedImagesLoadMore(params));

    };


    // const { lastElement } = useObserver({
    //     loading: suggestedImages.status === "loading",
    //     hasMore: suggestedImages?.current_page < suggestedImages?.last_page,
    //     callback: handleSuggestedImagesLoadMore,
    // });


    const getSelectedImagesCount = () => {
        const simages =
            Array.isArray(suggestedImages?.data) === true
                ? suggestedImages?.data
                : [];

        const oimages =
            Array.isArray(allImages?.data) === true ? allImages?.data : [];

        const images = [...simages, ...oimages].filter(
            (item) => item.album_image[0].is_selected == 1
        );
        return images.length;
    };

    const totalCount = () => {
        let count = suggestedImages?.total + allImages?.total
        return count;
    }


    const handleDeSelectAll = () => {
        dispatch(selectAllButton(false));
    }


    return {
        // lastElement,
        getSelectedImagesCount,
        totalCount,
        handleDeSelectAll
    }
}

