import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  userProfileData: {},
};

export const getUserBasicData = createAsyncThunk(
  "userData/getUserBasicData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("user-basic-data");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userBasicDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateHeaderState: (state, action) => {
      state.userProfileData.logo_url = action.payload;
    },

    updatePreferenceList: (state, action) => {
      if (action.payload.type == "address") {
        state.userProfileData = {
          ...state.userProfileData,
          preference: {
            ...state.userProfileData.preference,
            [action.payload.type]: action.payload.data,
          },
        };
      }
      state.userProfileData[action.payload.type] = action.payload.data;
    },

    updateThemeState: (state, action) => {
      state.userProfileData.theme_class_name = action.payload;
    },

    updateSocialMedia: (state, action) => {
      state.userProfileData = {
        ...state.userProfileData,
        preference: {
          ...state.userProfileData.preference,
          social_media: [...action.payload],
        },
      };
    },
  },
  extraReducers: {
    [getUserBasicData.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getUserBasicData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.userProfileData = action.payload.data.result;
    },
    [getUserBasicData.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const {
  updateHeaderState,
  updatePreferenceList,
  updateThemeState,
  updateSocialMedia,
} = userBasicDataSlice.actions;

export default userBasicDataSlice.reducer;
