import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBasicData } from "../../store/slices/GetStarted/userBasicDataSlice";

export const useHeader = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);
  const { registerData } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (Object.keys(userProfileData).length == 0) {
  //     dispatch(getUserBasicData());
  //   }
  // }, []);

  return {
    userProfileData,
  };
};
