import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDimensions } from "../../../Logic/Dimensions";
import { useFolderview } from "../../../Logic/UserFlow/useFolderview";
import {
  currentTabAction,
  updatePreselect,
} from "../../../store/slices/UserFlow/userFlowSlice";
import Style from "./FolderView.module.scss";
import { useUserFlow } from "../../../Logic/UserFlow/useUserFlow";
import { useParams } from "react-router-dom";
import RemovePhotosPopup from "../RemovePhotosPopup";
import ConfirmSelectionModal from "../FinalConfirmationPopup";
import GoWithStudioSuggestionModal from "../GoWithStudioSuggestionModal";
import ThankYouModal from "../ThankYouModal";
import RemoveSelectedPhotosModal from "../RemoveSelectedPhotosModal";
import MobileSubmitButton from "../MobileSubmitButton";
import DesktopEditButton from "../DesktopEditButton";
import DesktopSubmitButton from "../DesktopSubmitButton";
import FinalViewSelectAllButton from "../FinalViewSelectAllButton";
import FinalViewCancelButton from "../FinalViewCancelButton";
import PhotosViewHeading from "../PhotosViewHeading";
import PhotosViewCommonButton from "../PhotosViewCommonButton";
import Tabs from "../Tabs";
import StartSelectionButton from "../StartSelectionButton";

const FolderView = () => {
  let { user_token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const windowDimensions = useDimensions();
  const dispatch = useDispatch();

  const [isSelected, setIsSelected] = useState(false);
  const [isPreselect, setIsPreselect] = useState(false);
  const [isStartSelect, setIsStartSelect] = useState(false);
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [isRemoveSelected, setIsRemoveSelected] = useState(false);
  const [removeFromSelection, setRemoveFromSelection] = useState(false);
  const [goWithStudioSuggestion, setGoWithStudioSuggestion] = useState(false);
  const [conformRemoveFromSelection, setConformRemoveFromSelection] =
    useState(false);
  const [isShowTabs, setIsShowTabs] = useState(false);
  const [isShowTabsSmooth, setIsShowTabsSmooth] = useState(true);
  const [isShowFolderViewFooter, setIsShowFolderViewFooter] = useState(false);

  const {
    currentTab,
    sections,
    status,
    getSelectedImages,
    suggestedImages,
    allImages,
    finalSelection,
  } = useSelector((state) => state.userFlow);

  const [isPreselectedImages, setIsPreselectedImages] = useState(
    getSelectedImages?.preSelectedImage?.length
  );

  let albumName = sections["all"]?.data?.[0]?.album_image?.[0]?.album?.name;

  const {
    isSelectionModal,
    setIsSelectionModal,
    isThanksModal,
    isConfirmSelectionModal,
    setIsConfirmSelectionModal,
    setIsThanksModal,
    handleConfirm,
    handleFetchSuggestedImages,
    handleFetchAllImages,
    handleToggleStartSelect,
    handleAddImagesToFinalSelection,
    handlePreSelect,
    capitalizeFirstLetter,
    handleGoWithStudioSuggestions,
    getSelectedImagesCount,
    handleUpdateGoWithSuggestionsPopups,
    handleSelectAllAction,
    backHome,
  } = useFolderview();

  const { getUserFlowData } = useUserFlow();

  useEffect(() => {
    getUserFlowData();
  }, []);

  useEffect(() => {
    handleFetchSuggestedImages();
    handleFetchAllImages();
  }, []);

  useEffect(() => {
    setIsShowTabs(
      location.pathname?.replace(/\/$/, "") === `/${user_token}/folder-view`
        ? !getSelectedImages?.preSelect
        : location.pathname === `/${user_token}/folder-view/selected`
        ? !isEditSelected
        : ""
    );
    setIsPreselect(getSelectedImages?.preSelect);
  }, [getSelectedImages?.preSelect, location.pathname, isEditSelected]);

  useEffect(() => {
    setIsShowFolderViewFooter(currentTab == "all" || isEditSelected);
  }, [location.pathname, currentTab, isEditSelected]);

  useEffect(() => {
    if (status === "succeed") {
      setIsShowTabsSmooth(isShowTabs);
    }
  }, [isShowTabs]);

  return (
    <section className={Style.FolderView}>
      <Container>
        <div className={Style.user_flow_folderview}>
          <Row className={Style.user_flow_folderview_header}>
            <Col
              xl="12"
              className={`d-flex ${!isShowTabs && Style.moble_top_header} ${
                Style.user_flow_folderview_header_top
              }`}
            >
              {/************************************
               * PhotosViewHeading is COMMON FOR BOTH DESKTOP & MOBILE *
               ************************************/}
              <PhotosViewHeading
                status={status}
                capitalizeFirstLetter={capitalizeFirstLetter}
                albumName={albumName}
                isPreselect={isPreselect}
                PhotosCount={getSelectedImagesCount()}
                windowDimensions={windowDimensions}
              />

              {/************************************
               * PhotosViewCommonButton COMMON FOR BOTH DESKTOP & MOBILE *
               ************************************/}

              {location.pathname?.replace(/\/$/, "") ===
                `/${user_token}/folder-view` && (
                <PhotosViewCommonButton
                  handlePreSelect={handlePreSelect}
                  isPreselect={isPreselect}
                />
              )}

              {location.pathname === `/${user_token}/folder-view/selected` &&
                finalSelection?.data?.length > 0 && (
                  <>
                    {!isEditSelected ? (
                      <>
                        {windowDimensions.width >= 768 && (
                          <DesktopSubmitButton
                            onClick={setIsConfirmSelectionModal}
                          />
                        )}

                        <DesktopEditButton
                          setIsEditSelected={setIsEditSelected}
                        />
                      </>
                    ) : (
                      <>
                        {windowDimensions.width >= 768 && (
                          <FinalViewSelectAllButton
                            isSelected={isSelected}
                            setIsSelected={setIsSelected}
                          />
                        )}
                        <FinalViewCancelButton
                          setIsEditSelected={setIsEditSelected}
                          windowDimensions={windowDimensions}
                        />
                      </>
                    )}
                  </>
                )}
            </Col>
            {isShowTabs ? (
              <>
                <Tabs
                  sections={sections}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  isShowTabsSmooth={isShowTabsSmooth}
                />
              </>
            ) : (
              <></>
            )}

            <div
              className={`
              ${isShowTabsSmooth ? Style.FolderView_header_smooth_remove : ""}
              ${Style.FolderView_header_underline}
            `}
            ></div>
          </Row>

          <Outlet />
        </div>
      </Container>

      {isShowFolderViewFooter ? (
        <>
          <div className={Style.user_flow_folderview_footer}>
            <div className={Style.user_flow_folderview_footer_wrap}>
              <Container>
                <Row>
                  {!isEditSelected &&
                    isPreselect &&
                    windowDimensions.width >= 768 && (
                      <>
                        <Col md className="my-auto">
                          <p className="mb-0">
                            Selected photos will be added to the final
                            selection.
                          </p>
                        </Col>
                      </>
                    )}
                  {!isEditSelected ? (
                    <>
                      <Col
                        md
                        className={`text-center text-md-end my-auto ${Style.user_flow_folderview_footer_btns}`}
                      >
                        {isPreselect ? (
                          <>
                            <button
                              disabled={
                                [
                                  ...suggestedImages?.data,
                                  ...allImages?.data,
                                ].findIndex(
                                  (item) => item.album_image[0].is_selected == 1
                                ) === -1
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setIsSelected(true);
                                dispatch(currentTabAction("Selected"));
                                navigate(`/${user_token}/folder-view/selected`);
                                handleAddImagesToFinalSelection();
                              }}
                              className={`btn btn-primary ms-auto 
                          ${Style.go_to_suggestion_btn}
                          ${
                            isPreselectedImages ===
                              sections[currentTab]?.preSelectedImage?.length &&
                            "btn-disabled"
                          }
                        `}
                            >
                              {windowDimensions.width >= 768 ? (
                                "Add Photos"
                              ) : (
                                <>
                                  {getSelectedImagesCount() === 0 ? (
                                    "Add to final selection"
                                  ) : (
                                    <>Add {getSelectedImagesCount()} photos</>
                                  )}
                                </>
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            {windowDimensions.width > 768 && (
                              <share-album
                                setGoWithStudioSuggestion={
                                  setGoWithStudioSuggestion
                                }
                              />
                            )}

                            {suggestedImages?.data?.length > 0 &&
                              windowDimensions.width >= 768 && (
                                <button
                                  className={`btn btn-border-grey btn-border-black ms-auto me-3 ${Style.go_to_suggestion_btn}`}
                                  onClick={() =>
                                    handleUpdateGoWithSuggestionsPopups(true)
                                  }
                                >
                                  Go with studio suggestion
                                </button>
                              )}

                            <StartSelectionButton
                              setIsStartSelect={setIsStartSelect}
                              clickAction={handleToggleStartSelect}
                              handlePreSelect={handlePreSelect}
                            />
                          </>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md="9" className="my-auto">
                        <p
                          className={`mb-0 ${Style.Selected_photos_remove_content}`}
                        >
                          Selected photos will be removed from the final
                          selection.
                        </p>
                      </Col>

                      <Col
                        md
                        className={`text-center text-md-end my-auto ${Style.user_flow_folderview_footer_btns}`}
                      >
                        <>
                          <button
                            disabled={
                              finalSelection.selectedPhotos.length > 0
                                ? false
                                : true
                            }
                            className={`btn btn-danger ms-auto ${
                              Style.Selected_photos_remove_btn
                            } ${
                              finalSelection.selectedPhotos.length > 0
                                ? ""
                                : "btn-disabled"
                            } `}
                            onClick={() => setRemoveFromSelection(true)}
                          >
                            Remove
                          </button>
                        </>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </>
      ) : windowDimensions.width < 768 ? (
        <MobileSubmitButton
          isSelectionModal={isSelectionModal}
          setIsSelectionModal={setIsSelectionModal}
          finalSelection={finalSelection}
          setIsConfirmSelectionModal={setIsConfirmSelectionModal}
        />
      ) : (
        <></>
      )}

      <RemoveSelectedPhotosModal
        showModal={conformRemoveFromSelection}
        setShowModal={setConformRemoveFromSelection}
      />

      <GoWithStudioSuggestionModal
        showModal={getSelectedImages.goWithSuggestionsPopups}
        setShowModal={handleUpdateGoWithSuggestionsPopups}
        handleConfirm={handleGoWithStudioSuggestions}
      />

      <RemovePhotosPopup
        removeFromSelection={removeFromSelection}
        setRemoveFromSelection={setRemoveFromSelection}
        setIsEditSelected={setIsEditSelected}
      />

      <ConfirmSelectionModal
        isConfirmSelectionModal={isConfirmSelectionModal}
        setIsConfirmSelectionModal={setIsConfirmSelectionModal}
        isAPIloading={finalSelection.status}
        handleConfirm={handleConfirm}
        setRemoveFromSelection={setRemoveFromSelection}
      />

      <ThankYouModal
        backHome={backHome}
        showModal={isThanksModal}
        setShowModal={setIsThanksModal}
      />
    </section>
  );
};

export default FolderView;
